<template>
  <div class="container" v-if="screenConfig !== null">
    <h2 class="text-center">
      Hier volgen {{numberOfQuestions}} waarden! Selecteer de waarden die het beste bij jou passen.
    </h2>

    <div class="cards-container">
      <explanation-card
        :headerText="screenConfig.click_card_text"
        :symbolName="screenConfig.click_card_symbol"
        :alt="screenConfig.click_card_image_alt"
        :secondaryText="screenConfig.click_card_secondary_text"
      />

      <explanation-card
        :headerText="screenConfig.turn_card_text"
        :symbolName="screenConfig.turn_card_symbol"
        :alt="screenConfig.turn_card_image_alt"
        :secondaryText="screenConfig.turn_card_secondary_text"
      />

      <explanation-card
        :headerText="screenConfig.next_round_text"
        :symbolName="screenConfig.next_round_symbol"
        :alt="screenConfig.next_round_image_alt"
        :secondaryText="screenConfig.next_round_secondary_text"
      />
    </div>

    <h4>{{screenConfig.warning_text}}</h4>

    <h2>{{screenConfig.ready_to_go_text}}</h2>

    <button class="primary-button-orange" @click="initializeTest">
      <span v-if="!cardsLoading">{{screenConfig.start_test_button_text}}</span>
      <beat-loader v-if="cardsLoading" color="white"></beat-loader>
    </button>

    <button class="secondary-button-blue" @click="disclaimerVisible = true">
      Disclaimer
    </button>

    <transition name="fade">
      <div class="modal-overlay" v-if="disclaimerVisible">
        <div>
          <p>
            {{$styleConfiguration.test_disclaimer}}
          </p>
          <button class="secondary-button-blue" @click="disclaimerVisible = false">
            Sluiten
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import ExplanationCard from '@/components/tests/swipeableCards/ExplanationCard.vue';

export default {
  name: 'WaardenExplanation',
  data() {
    return {
      disclaimerVisible: false,
      screenConfig: null,
    };
  },
  props: {
    numberOfQuestions: {
      type: Number,
      requited: true,
    },
    cardsLoading: {
      type: Boolean,
      requited: true,
    },
  },
  components: {
    ExplanationCard,
    BeatLoader,
  },
  methods: {
    initializeTest() {
      if (!this.cardsLoading) {
        this.$emit('startTestClicked');
        // Remove any old answers, if there are any
        this.$store.dispatch('testData/reset');
      }
    },
  },
  mounted() {
    this.screenConfig = this.$styleConfiguration.test_welcome_screen_info[this.$route.params.type];
  },
};
</script>

<style lang="sass" scoped>
.container
  @include flex-column(center)
  align-items: center
  text-align: center

  & button
    margin-bottom: 20px

h2
  @include xs-max
    margin: 15px 20px
  @include sm
    width: 450px

.cards-container
  @include xs-max
    @include flex-column(center)
    align-items: center
  @include sm
    @include flex-row(center)

.primary-button-orange
  @include xs-max
    width: 90vw
  @include sm
    width: 300px

.warning-box
  background-color: rgba(255,255,255,0.5)
  color: $red
  margin: 0 10px
  padding: 10px

.secondary-button-blue
  font-size: 1em

.modal-overlay
  @include flex-column(center)
  align-items: center
  position: fixed
  width: 100vw
  height: 100vh
  background-color: rgba(black, 0.3)
  div
    background-color: white
    border-radius: 4px
    padding: 2em
    max-width: 500px
    margin: 1em
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.30)
    -moz-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.30)
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.30)
</style>
