<template>
  <div id="app">
    <navigation-bar/>
    <router-view/>
    <app-footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import AppFooter from '@/components/AppFooter.vue';
import NavigationBar from '@/components/NavigationBar.vue';
import ifvisible from 'ifvisible.js';
import { Authentication } from '../../Shared/lib/helpers/Authentication';

export default {
  name: 'App',
  components: {
    AppFooter,
    NavigationBar,
  },
  data: () => {
    return {
      hidden: null,
      visibilityChange: null,
    };
  },
  methods: {
    enableWakeup() {
      this.checkAuthentication();
      // We remove any old event triggers
      ifvisible.off('wakeup');

      // Then we add a trigger to keep track of sign in/out events in other windows
      ifvisible.on('wakeup', () => {
        this.checkAuthentication();
      });
    },
    checkAuthentication() {
      // Check if the CSST token exists
      if (Authentication.getCsstCookie() === null && Authentication.userIsSignedIn()) {
        // If not, and if we are signed in we want to log out
        Authentication.signOut()
          .then(() => {
            this.$store.dispatch('riasocResults/flushData');
            this.$store.dispatch('identiteitResults/flushData');
            if (this.$router.currentRoute.name !== 'Home') {
              this.$router.push({ name: 'Home' });
            }
          })
          .catch(() => {
            console.log('[1] error');
          });
      } else if (Authentication.getCsstCookie() === null && !Authentication.userIsSignedIn()) {
        this.$store.dispatch('user/fetchUser', null);
      } else if (Authentication.getCsstCookie() !== null && !Authentication.userIsSignedIn()) {
        // If it does, and we are not signed in, we want to sign in
        Authentication.signInIfCsstTokenPresent()
          .then(() => { })
          .catch(() => {
            console.log('auth error');
          });
      }
    },
  },
  mounted() {
    // First we check if we should already sign in the user based on the CSST token
    Authentication.signInIfCsstTokenPresent()
      .then(() => {
        this.enableWakeup();
      })
      .catch(() => {
        this.enableWakeup();
      });
  },
};
</script>

<style lang="sass">
body
  margin: 0

#app
  font-family: 'Montserrat', Helvetica, Arial, Verdana, Tahoma, sans-serif !important
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $dark-blue
  @include flex-column(space-between)

  min-height: 100vh

  & .logo-home-button
    position: absolute
    padding: 2em $standard-margin-size
    & img
      height: 60px
      width: auto

.toaster-error
  font-family: 'Montserrat', Helvetica, Arial, Verdana, Tahoma, sans-serif
  font-weight: 600 !important
</style>
