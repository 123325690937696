<template>
  <button class='action-button' @click="$emit('clicked')">
    <img
      :src="url"
      :alt="alt"
    />
  </button>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    symbolName: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  computed: {
    url() {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      const url = require(`@/assets/images/${this.symbolName}`);
      return url;
    },
  },
};
</script>
