<template>
  <div class="round-indicator-container">
    <p v-if="remainingCards > 0">{{currentMessage}}</p>

    <button @click="$emit('nextRoundPressed')" v-if="remainingCards == 0">
      {{buttonMessage}}
      <img src="@/assets/images/waarden/next_round_icon.png" alt="Kieswijs Analyse Logo"/>
    </button>
  </div>
</template>

<script>

export default {
  name: 'WaardenExplanation',
  props: {
    roundNumber: {
      type: Number,
      required: true,
      // 0: Select 15
      // 1: Select 5
      // 2: Examine
      // 3: Select 5 worst
    },
    selectedCards: {
      type: Number,
      required: true,
    },
    allowedNumberOfCards: {
      type: Number,
      required: true,
    },
  },
  computed: {
    // a computed getter
    currentMessage() {
      if (this.roundNumber === 0) {
        return `Ronde 1: Kies de ${this.allowedNumberOfCards} belangrijkste waarden! (Nog ${this.remainingCards} te gaan!)`;
      }

      if (this.roundNumber === 1) {
        return `Ronde 2: Kies nu nog ${this.allowedNumberOfCards} belangrijkste waarden! (Nog ${this.remainingCards} te gaan!)`;
      }

      if (this.roundNumber === 2) {
        return `Ronde 3: Je moet nog ${this.allowedNumberOfCards} waarden kiezen`;
      }

      if (this.roundNumber === 3) {
        return `Ronde 4: En nu de ${this.allowedNumberOfCards} waarden die NIET bij jou passen! (Nog ${this.remainingCards} te gaan!)`;
      }

      return 'Ga door!';
    },
    buttonMessage() {
      if (this.roundNumber === 2) {
        return 'Check of jouw waarden kloppen! Je mag nu nog wisselen.';
      }
      return 'Ga Door!';
    },
    remainingCards() {
      return this.allowedNumberOfCards - this.selectedCards;
    },
  },
};
</script>

<style lang="sass" scoped>
.round-indicator-container
  @include shadow(0, 2px, 10px, 1px, rgba(0, 0, 0, 0.3))
  @include flex-row(center)
  position: fixed
  bottom: 0
  right: 10vw
  left: 10vw
  background-color: $gray-light

  padding: 1em
  font-weight: bold
  margin-bottom: 2em
  border-radius: 15px
  color: $orange
  font-size: 1.2em
  text-align: center

  & button
    @include flex-row(center)
    @include basicButton
    align-items: center
    color: $orange
    font-size: 1em
    background-color: transparent
    & img
      width: 3em
      margin-left: 1em

</style>
