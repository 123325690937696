import AnswerSubmissionLog from '../AnswerSubmissionLog';

const toFirestore = (answerSubmissionLog) => {
  return {
    answer_document_id: answerSubmissionLog.answerDocumentId,
    is_update_of_answers: answerSubmissionLog.isUpdateOfAnswers,
    question_type: answerSubmissionLog.questionType,
    upload_date: answerSubmissionLog.uploadDate,
    user_email: answerSubmissionLog.userEmail,
    user_name: answerSubmissionLog.userName,
    school_name: answerSubmissionLog.schoolName,
  };
};

const fromFirestore = (snapshot, options) => {
  const data = snapshot.data(options);
  return new AnswerSubmissionLog(
    data.answer_document_id,
    data.is_update_of_answers,
    data.question_type,
    data.upload_date,
    data.user_email,
    data.user_name,
    data.school_name,
  );
};

export {
  toFirestore,
  fromFirestore,
};
