const questionTypeToTestName = (testName) => {
  let result;
  switch (testName) {
    case 'riasoc_general':
      result = 'RIASOC Algemeen';
      break;
    case 'riasoc_kiesreis':
      result = 'RIASOC Kiesreis';
      break;
    case 'identiteit':
      result = 'Identiteit';
      break;
    case 'waardenspel':
      result = 'Waardenspel';
      break;
    default:
      result = testName.replaceAll('_', ' ');
      break;
  }

  return result.charAt(0).toUpperCase() + result.slice(1);
};

export {
  questionTypeToTestName,
};
