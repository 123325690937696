<template>
    <div class="tests-container">
        <div class="inner-tests-container">
            <div
                class="blue-container-rounded-corners title-container"
                ref="titleContainer"
            >
                <h3 v-html="$styleConfiguration.test_introduction_text"></h3>
            </div>

            <div class="test-button-container">
                <test-item-container
                    v-for="test in $styleConfiguration.tests"
                    :key="test.id"
                    class="test-container"
                    routeName="TestEnvironment"
                    :title="test.title"
                    :description="test.description"
                    :duration="`${test.min_duration}-${test.max_duration}`"
                    :testTypeName="test.test_type_name"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TestItemContainer from '@/components/home/TestItemContainer.vue';

export default {
  name: 'TestsBody',
  components: {
    TestItemContainer,
  },
  mounted() {
    this.configureStyles();
  },
  methods: {
    configureStyles() {
      this.$refs.titleContainer.style.backgroundColor = this.$styleConfiguration.light_gray_hex;
    },
  },
};
</script>

<style scoped lang="sass">
.tests-container
    @include flex-row(center)
    flex-wrap: wrap !important

.inner-tests-container
    padding: 2em

    & .test-button-container
        width: 100%
        @include flex-row(space-between)
        flex-wrap: wrap

    & .test-container
        margin-top: 3em
        @include md
            width: 25%
        @include sm-max
            width: 100%
</style>
