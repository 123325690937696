<template>
  <div>
    <background-triangle/>
    <cards-stack-game
      v-if="this.$route.params.type == 'riasoc_general'
            || this.$route.params.type == 'identiteit'
            || this.$route.params.type == 'riasoc_kiesreis'"
    ></cards-stack-game>

    <waarden-game
      v-if="this.$route.params.type == 'waardenspel'"
    >
    </waarden-game>
  </div>
</template>

<script>
import CardsStackGame from '@/components/tests/swipeableCards/CardsStackGame.vue';
import WaardenGame from '@/components/tests/waarden/WaardenGame.vue';
import BackgroundTriangle from '@/components/BackgroundTriangle.vue';

export default {
  name: 'TestEnvironment',
  components: {
    BackgroundTriangle,
    CardsStackGame,
    WaardenGame,
  },
};
</script>

<style lang="sass" scoped>
.test-container
  @include sm-max
    padding: $standard-margin-size 0

.game-card-stack-container
  @include flex-column(center)
  @include sm-max
    min-height: calc(100vh - #{$footer-height} - 2 * #{$standard-margin-size})
  @include md
    min-height: calc(100vh - #{$footer-height})

.centering
  @include flex-column(center)
  @include sm-max
    min-height: calc(100vh - #{$footer-height} - 2 * #{$standard-margin-size})
  @include md
    min-height: calc(100vh - #{$footer-height})

.fade-enter-active, .fade-leave-active
  transition: opacity 1s

.fade-enter, .fade-leave-to
  opacity: 0

.vue-progress-path
  align-self: center
</style>
