<template lang="html">
  <div class="navbar-container" :class="{
    'navbar-container-expanded': (userId != '' && userId != null),
  }">
    <router-link :to="{ name: 'Home' }">
      <img
        :src="require(`@/${$styleConfiguration.navbar_logo_path}`)"
        :alt="$styleConfiguration.navbar_logo_alt">
    </router-link>

    <router-link
      v-if="userId == '' || userId == null"
      :to="{ name: 'Account' }"
      class="icon-container"
    >
      <font-awesome-icon icon="sign-in-alt" class="icon"/>
      <p class="icon-subtitle">Inloggen</p>
    </router-link>

    <a
      v-if="userId != '' && userId != null"
      :href="getAccountUrl()"
      target="_blank"
      class="icon-container"
    >
      <font-awesome-icon icon="user-circle" class="icon"/>
      <p class="icon-subtitle">Account</p>
    </a>

    <router-link
      v-if="userId != '' && userId != null"
      :to="{ name: 'Results', params: { testIdentifier: 'unk' }, }"
      class="icon-container"
    >
      <font-awesome-icon icon="poll" class="icon"/>
      <p class="icon-subtitle">Resultaten</p>
    </router-link>

    <button class="icon-container" v-if="userId != '' && userId != null" @click="logout">
      <font-awesome-icon icon="sign-out-alt" class="icon"/>
      <p class="icon-subtitle">Uitloggen</p>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Constants from '@/constants';

import { Authentication } from '../../../Shared/lib/helpers/Authentication';

export default {
  name: 'NavigationBar',
  methods: {
    logout() {
      Authentication.signOut()
        .then(() => {
          if (this.$router.currentRoute.name !== 'Home') {
            this.$router.push({ name: 'Home' });
          }
          this.$store.dispatch('riasocResults/flushData');
          this.$store.dispatch('identiteitResults/flushData');
        });
    },
    getAccountUrl() {
      if (window.location.href.includes('localhost')) {
        return Constants.DEV_MIJN_KIESWIJS_BASE_URL;
      }
      return Constants.MIJN_KIESWIJS_BASE_URL;
    },
  },
  computed: {
    ...mapGetters({
      userId: 'user/id',
    }),
  },
};
</script>

<style lang="sass" scoped>
.navbar-container
  @include flex-row(center)
  height: $navigation-bar-height
  margin-left: 1em
  left: 0
  position: absolute
  top: 0
  z-index: 100

img
  height: 80%
  padding-top: 10%
  width: 1.5 * $navigation-bar-height
  object-fit: contain

.icon-container
  @include basicButton
  @include flex-column(center)
  padding: 0
  margin: 0
  align-items: center
  height: 100%
  width: $navigation-bar-icon-width
  background-color: transparent

.icon
  width: 25px
  height: 25px

.icon-subtitle
  margin: 2px 0 0 0 !important
  font-size: 8px !important

.icon-container
  text-decoration: none !important
  color: $gray
</style>
