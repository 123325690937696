<template>
  <div class="content-container">
    <scale-loader v-if="chartLoading" color="#F26000"></scale-loader>

    <div v-if="!chartLoading">
      <h2>Jouw {{waardenResults.selected.length}} Geselecteerde Waarden</h2>
      <waarden-cards-view
        :allCards="waardenResults.selected"
      />

      <h2>{{waardenResults.notFitting.length}} waarden die totaal niet bij jou passen</h2>
      <waarden-cards-view
        :allCards="waardenResults.notFitting"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// eslint-disable-next-line import/extensions
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import WaardenCardsView from '../../tests/waarden/WaardenCardsView.vue';

export default {
  name: 'WaardenResultsBody',
  data() {
    return {
      chartLoading: false,
    };
  },
  components: {
    ScaleLoader,
    WaardenCardsView,
  },
  computed: {
    ...mapGetters({
      waardenResults: 'waardenResults/waardenResults',
    }),
  },
  mounted() {
    this.chartLoading = true;

    if (this.waardenResults) {
      this.chartLoading = false;
    }
  },
  watch: {
    waardenResults() {
      this.chartLoading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
a
  margin: 10px  !important
  text-decoration: none

.travel-buttons-container
  @include sm-max
    @include flex-column(space-between)
  @include md
    @include flex-row(space-between)
    flex-grow: 1

.content-container
  @include flex-column(center)
  padding: 0 $standard-margin-size
  @include sm-max
    width: 100vw
  & button
    margin: 20px
  & p
    margin: 20px

.primary-button-orange
  flex-basis: 100%
</style>
