import UserData from '../UserData';

const toFirestore = (userData) => {
  let lowerCaseClassName = userData.className;
  if (userData.className) {
    lowerCaseClassName = lowerCaseClassName.toLowerCase();
  }

  let lowercaseSchoolName = userData.schoolName;
  if (userData.schoolName) {
    lowercaseSchoolName = lowercaseSchoolName.toLowerCase();
  }

  return {
    uid: userData.uid,
    firstName: userData.firstName,
    lastName: userData.lastName,
    firstNameLowercase: userData.firstNameLowercase,
    lastNameLowercase: userData.lastNameLowercase,
    kieswijsCode: userData.kieswijsCode,
    currentEducation: userData.currentEducation,
    educationYear: userData.educationYear,
    schoolName: lowercaseSchoolName,
    className: lowerCaseClassName,
    accountType: userData.accountType,
    // We do not include the code here as it is read-only
  };
};

const fromFirestore = (snapshot, options) => {
  const data = snapshot.data(options);

  return new UserData(
    data.uid,
    data.firstName,
    data.lastName,
    data.firstNameLowercase,
    data.lastNameLowercase,
    data.kieswijsCode,
    data.accountType,

    data.currentEducation,
    data.educationYear,
    data.schoolName,
    data.className,

    data.childKieswijsCodes,
  );
};

export {
  toFirestore,
  fromFirestore,
};
