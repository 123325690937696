<template>
  <div class="test-container centering">
    <transition name="fade" mode="out-in">
      <div class="game-card-stack-container" v-if="!showingExplanation">
        <waarden-cards-view
          :allCards="allCards"
          @selectedCard="selectedCard"
        />
        <waarden-round-indicator
          :roundNumber="roundNumber"
          :selectedCards="selectedCardCount"
          :allowedNumberOfCards="allowedSelectionNumber"
          @nextRoundPressed="nextRoundPressed"
        />
      </div>

      <waarden-explanation
        v-if="showingExplanation"
        @startTestClicked="startTest"
        :cardsLoading="cardsLoading"
        :numberOfQuestions="totalNumberOfCards"
      />
    </transition>
  </div>
</template>

<script>
import WaardenExplanation from './WaardenExplanation.vue';
import WaardenRoundIndicator from './WaardenRoundIndicator.vue';
import WaardenCardsView from './WaardenCardsView.vue';

import { QuestionAPI } from '../../../../../Shared/index';

export default {
  name: 'WaardenGame',
  data() {
    return {
      showingExplanation: true,
      cardsLoading: true,

      roundNumber: 0,
      allCards: [],
      unusedCards: [],
      finalSelectedCards: [],
      selectedCardCount: 0,
      totalNumberOfCards: 0,
    };
  },
  components: {
    WaardenExplanation,
    WaardenRoundIndicator,
    WaardenCardsView,
  },
  methods: {
    startTest() {
      this.showingExplanation = false;

      // To make sure the user is at the top of the page when the card swipe
      // shows up, scroll the user to the top of the page
      window.scrollTo(0, 0);
    },
    shuffleArray(array) {
      const dupArray = array;
      for (let i = dupArray.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * i);
        const temp = dupArray[i];
        dupArray[i] = dupArray[j];
        dupArray[j] = temp;
      }
      return dupArray;
    },

    selectedCard(card) {
      if (card.answer) {
        this.moveWaardeToDeselected(card);
      } else {
        if (this.selectedCardCount >= this.allowedSelectionNumber) return;
        this.moveWaardeToSelected(card);
      }
    },
    nextRoundPressed() {
      this.moveToNextRound();
    },

    moveWaardeToSelected(card) {
      const index = this.allCards.findIndex((item) => item.card.id === card.card.id);
      if (index > -1) {
        this.allCards[index].answer = true;
      }
      this.countSelectedCards();
    },
    moveWaardeToDeselected(card) {
      const index = this.allCards.findIndex((item) => item.card.id === card.card.id);
      if (index > -1) {
        this.allCards[index].answer = false;
      }
      this.countSelectedCards();
    },
    countSelectedCards() {
      this.selectedCardCount = this.allCards.filter((card) => (card.answer)).length;
    },
    moveToNextRound() {
      // 0: Select 15
      // 1: Select 5
      // 2: Examine
      // 3: Select 5 worst
      const nextRoundNumber = this.roundNumber + 1;
      if (nextRoundNumber === 1) {
        // Select Less
        this.unusedCards = this.allCards.filter((card) => !card.answer);
        this.allCards = this.allCards.filter((card) => card.answer);

        this.allCards.forEach((card) => {
          // eslint-disable-next-line no-param-reassign
          card.answer = false;
        });
      } else if (nextRoundNumber === 2) {
        // Examine (sorted Combine)
        this.allCards = this.allCards.concat(this.unusedCards);
      } else if (nextRoundNumber === 3) {
        // Save selected and show all unselected
        this.finalSelectedCards = this.allCards.filter((card) => card.answer);
        this.allCards = this.allCards.filter((card) => !card.answer);
      } else if (nextRoundNumber === 4) {
        this.moveToNextView();
      }

      this.countSelectedCards();

      this.roundNumber = nextRoundNumber;
    },
    moveToNextView() {
      const testName = this.$route.params.type;
      const answers = {
        selected: this.finalSelectedCards.map((card) => {
          return { answer: card.answer, qid: card.card.id };
        }),
        not_selected: this.allCards
          .filter((card) => !card.answer)
          .map((card) => {
            return { answer: card.answer, qid: card.card.id };
          }),
        not_fitting: this.allCards
          .filter((card) => card.answer)
          .map((card) => {
            return { answer: card.answer, qid: card.card.id };
          }),
      };

      this.$store.commit(
        'testData/setTestName', testName,
      );

      this.$store.commit(
        'testData/setAnswers', answers,
      );

      this.$router.push({ name: 'Account' });
    },
  },
  mounted() {
    this.cardsLoading = true;
    QuestionAPI.getAll(this.$route.params.type)
      .then((snapshot) => {
        let questions = [];
        snapshot.forEach((doc) => {
          questions = doc.data().questions.filter((question) => question.questionActive);
        });

        questions = this.shuffleArray(questions);
        questions = questions.map(
          (singleQuestion) => {
            return {
              card: singleQuestion,
              answer: false,
            };
          },
        );

        this.totalNumberOfCards = questions.length;
        this.allCards = questions;
        this.cardsLoading = false;
      });
  },
  computed: {
    numberOfQuestions() {
      const count = this.visibleCards.length;
      return count;
    },
    allowedSelectionNumber() {
      if (this.roundNumber === 0) {
        return 15; // Select fitting
      }

      if (this.roundNumber === 1 || this.roundNumber === 2) {
        return 5; // Select fitting
      }

      if (this.roundNumber === 3) {
        return 5; // Select not fitting
      }

      return 0;
    },
  },
};
</script>

<style lang="sass" scoped>
.test-container
  @include sm-max
    padding: $standard-margin-size 0

.game-card-stack-container
  @include flex-column(center)
  @include sm-max
    min-height: calc(100vh - #{$footer-height} - 2 * #{$standard-margin-size})
  @include md
    min-height: calc(100vh - #{$footer-height})

  padding: 2em

.centering
  @include flex-column(center)
  @include sm-max
    min-height: calc(100vh - #{$footer-height} - 2 * #{$standard-margin-size})
  @include md
    min-height: calc(100vh - #{$footer-height})

.fade-enter-active, .fade-leave-active
  transition: opacity 1s

.fade-enter, .fade-leave-to
  opacity: 0

.vue-progress-path
  align-self: center
</style>
