/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import user from './modules/user';
import testData from './modules/testData';
import riasocResults from './modules/riasocResults';
import identiteitResults from './modules/identiteitResults';
import waardenResults from './modules/waardenResults';

Vue.use(Vuex);

const debug = true;
const plugins = [];

plugins.push(createPersistedState({ paths: ['user', 'permissions'] }));

export default new Vuex.Store({
  modules: {
    user,
    testData,
    riasocResults,
    identiteitResults,
    waardenResults,
  },
  strict: debug,
  plugins,
});
