<template>
  <div class="card-face-container" ref="cardFaceContainer">
    <img
      class="card-image"
      :src="sideImageUrl"
      :alt="sideImageAlt"
    />
  </div>
</template>

<script>
export default {
  name: 'RotatableCardFace',
  props: {
    isBack: {
      type: Boolean,
      default: false,
    },
    sideImageUrl: {
      type: String,
      required: true,
    },
    sideImageAlt: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.isBack) {
      this.$refs.cardFaceContainer.classList.toggle('is-back-face');
    }
  },
};
</script>

<style lang="sass" scoped>
.card-face-container
  @include card()
  border-radius: 50% !important
  height: 100%
  & img
    height: 100%
    object-fit: cover
    width: 100%
    border-radius: 50%

.is-back-face
  transform: rotateY(180deg)
  @include card()
  height: 100%
</style>
