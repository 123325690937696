<template lang="html">
  <div class="example-card">
    <div class="text-center">
      <action-button
        class="action-button mb-2"
        :symbolName="symbolName"
        :alt="alt"
      />

      <h4>{{ headerText }}</h4>
      <h5>{{ secondaryText }}</h5>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/tests/swipeableCards/ActionButton.vue';

export default {
  name: 'ExplanationCard',
  props: {
    headerText: {
      type: String,
      required: true,
    },
    secondaryText: {
      type: String,
      required: true,
    },
    symbolName: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  components: {
    ActionButton,
  },
};

</script>
<style lang="sass" scoped>
.example-card
  @include card()
  @include flex-row(center)
  margin: 15px
  padding: 30px 16px
  width: 180px

h4, h5
  margin: 4px

h5
  margin: 0 20px
</style>
