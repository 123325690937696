<template lang="html">
  <div class="scroll-down-hint-container" ref="scrollHintContainer" @click="scrollToBotton">
    <div class="content-container">
      <font-awesome-icon icon="chevron-down"/>
      <p>Scroll naar beneden</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollDownHint',
  methods: {
    scrollToBotton() {
      this.scrollIt(
        document.documentElement.scrollHeight,
        500,
        'easeInOutQuad',
      );
    },
    scrollIt(destinationY, duration = 200, easing = 'linear', callback = null) {
      const easings = {
        easeInOutQuad(t) {
          return (t < 0.5) ? (2 * (t * t)) : (-1 + ((4 - (2 * t)) * t));
        },
      };

      const start = window.pageYOffset;
      const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
      );
      const windowHeight = window.innerHeight
                 || document.documentElement.clientHeight
                 || document.getElementsByTagName('body')[0].clientHeight;
      const destinationOffsetToScroll = Math.round(
        documentHeight - destinationY < windowHeight
          ? documentHeight - windowHeight : destinationY,
      );

      if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
          callback();
        }
        return;
      }

      function scroll() {
        const now = 'now' in window.performance ? performance.now() : new Date().getTime();
        const time = Math.min(1, ((now - startTime) / duration));
        const timeFunction = easings[easing](time);
        window.scroll(
          0,
          Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start),
        );

        if (window.pageYOffset === destinationOffsetToScroll) {
          if (callback) {
            callback();
          }
          return;
        }
        requestAnimationFrame(scroll);
      }

      scroll();
    },
  },
  mounted() {
    const vue = this;
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY;
      const winHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;
      const perc = 100 * (scrollPos / (docHeight - winHeight));

      const LOWER_BOUND = 50;
      const UPPER_BOUND = 70;
      const hintContainer = vue.$refs.scrollHintContainer;
      if (hintContainer) {
        if (perc >= LOWER_BOUND && perc < UPPER_BOUND) {
          const newOpacity = 1 - (
            (perc - LOWER_BOUND) * (1 / (UPPER_BOUND - LOWER_BOUND))
          );
          hintContainer.style.opacity = newOpacity;
        } else if (perc < LOWER_BOUND) {
          hintContainer.style.opacity = 1.0;
        } else if (perc > UPPER_BOUND) {
          hintContainer.style.opacity = 0.0;
        }
      }
    });
  },
};
</script>

<style lang="sass" scoped>
.scroll-down-hint-container
  @include shadow(0, 2px, 5px, 1px, rgba(0, 0, 0, 0.12))
  @include basicButton
  background-color: white
  bottom: 0
  border-radius: 20px
  cursor: pointer
  margin: 10px
  padding: 10px 20px
  position: fixed
  right: 0
  z-index: 100

.content-container
  @include flex-row(center)
  align-items: center
  & p
    margin: 0 10px
    font-weight: bold
    font-size: .8em
</style>
