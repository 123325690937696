import { AnswerAPI, QuestionAPI } from '../../../../Shared/index';

const getters = {
  waardenResults: (state) => ({
    notFitting: state.notFitting,
    notSelected: state.notSelected,
    selected: state.selected,
    emptyResults: state.emptyResults,
    isVisibleForUser: state.isVisibleForUser,
  }),
  isVisibleForUser: (state) => state.isVisibleForUser,
};

const actions = {
  retrieveData(context, { userId, testName }) {
    QuestionAPI.getAll(testName)
      .then((snapshot) => {
        const questions = new Map();

        snapshot.forEach((doc) => {
          doc.data().questions.forEach((question) => {
            questions.set(question.id, question);
          });
        });
        AnswerAPI.retrieveAll(userId, testName)
          .then((answerSnapshot) => {
            let notFitting = [];
            let notSelected = [];
            let selected = [];

            answerSnapshot.forEach((doc) => {
              // Not Required Here
              // if (!doc.data().isVisibleForUser) {
              //   context.commit('hideResultsFromUser');
              //   return;
              // }
              context.commit('enableResultsForUser');
              const answers = doc.data().allAnswers;

              notFitting = answers.not_fitting.map(
                (q) => {
                  return {
                    card: questions.get(q.qid),
                    answer: q.answer,
                  };
                },
              );

              notSelected = answers.not_selected.map(
                (q) => {
                  return {
                    card: questions.get(q.qid),
                    answer: q.answer,
                  };
                },
              );

              selected = answers.selected.map(
                (q) => {
                  return {
                    card: questions.get(q.qid),
                    answer: q.answer,
                  };
                },
              );
            });
            context.commit('setScoreData', { notFitting, notSelected, selected });
          });
      });
  },
  flushData({ commit }) {
    commit('FLUSH_DATA');
  },
};

const mutations = {
  hideResultsFromUser(state) {
    state.isVisibleForUser = false;
  },
  enableResultsForUser(state) {
    state.isVisibleForUser = true;
  },
  setScoreData(state, response) {
    state.notFitting = response.notFitting;
    state.notSelected = response.notSelected;
    state.selected = response.selected;

    state.emptyResults = state.notFitting.length === 0
        && state.notSelected.length === 0
        && state.selected.length === 0;

    state.isVisibleForUser = true;
  },
  FLUSH_DATA(state) {
    state.notFitting = [];
    state.notSelected = [];
    state.selected = [];
    state.isVisibleForUser = false;
    state.emptyResults = false;
  },
};

export default {
  namespaced: true,
  state: {
    notFitting: [],
    notSelected: [],
    selected: [],
    emptyResults: true,
    isVisibleForUser: false,
  },
  getters,
  mutations,
  actions,
};
