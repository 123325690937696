<template>
  <div class="cards-buttons-container">
    <div class="cards-container">
      <h2>Dat waren ze!</h2>
      <div class="cards">

        <!-- If we play a riasoc game (prefixed with riasoc_) -->
        <div v-if="$route.params.type.includes('riasoc_')">
          <game-card
            v-for="(card, index) in cards"
            :ref="'gameCard-' + card.id"
            :key="card.question"
            :card="card"
            :is-current="index <= 1"
            @cardAccepted="$emit('cardAccepted', card)"
            @cardRejected="$emit('cardRejected', card)"
            @hideCard="$emit('hideCard')"
            @showingBack="enableActionButtons"
          />
        </div>

        <!-- If we play the identiteit game -->
        <div v-if="$route.params.type === 'identiteit'">
          <rotating-game-card
            v-for="(card, index) in cards"
            :ref="'gameCard-' + card.id"
            :key="card.question"
            :card="card"
            :is-current="index < 1"
            :is-visible="index < 3"
            @cardAccepted="$emit('cardAccepted', card)"
            @cardRejected="$emit('cardRejected', card)"
            @hideCard="$emit('hideCard')"
            @showingBack="enableActionButtons"
          />
        </div>
      </div>
    </div>

    <div class="button-container" ref="buttonContainer">
      <action-button
        symbolName="cardSwiping/declineButtonImage.png"
        alt="Dit is niets voor mij"
        @clicked="declineButtonClicked"
      />
      <action-button
        symbolName="cardSwiping/acceptButtonImage.png"
        alt="Dit lijkt mij leuk"
        @clicked="acceptButtonClicked"
      />
    </div>
  </div>
</template>

<script>
import GameCard from '@/components/tests/swipeableCards/GameCard.vue';
import RotatingGameCard from '@/components/tests/swipeableCards/RotatingGameCard.vue';
import ActionButton from '@/components/tests/swipeableCards/ActionButton.vue';

const ACCEPT_CARD = 'cardAccepted';
const REJECT_CARD = 'cardRejected';

export default {
  name: 'GameCardStack',
  data: () => {
    return {
      interactionButtonsEnabled: true,
    };
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
  components: {
    GameCard,
    ActionButton,
    RotatingGameCard,
  },
  methods: {
    enableActionButtons() {
      this.$refs.buttonContainer.classList.remove('disabled-cards-container');
    },
    acceptButtonClicked() {
      this.performActionOnCards(ACCEPT_CARD);
    },
    declineButtonClicked() {
      this.performActionOnCards(REJECT_CARD);
    },
    performActionOnCards(action) {
      if (!this.interactionButtonsEnabled) return;

      // Temporarily disable the button
      this.interactionButtonsEnabled = false;

      setTimeout(() => {
        // Reenable the button
        this.interactionButtonsEnabled = true;
      }, 1000);

      if (this.cards.length > 0) {
        const firstCard = this.cards[0];
        const cardObjects = this.$refs[`gameCard-${firstCard.id}`];
        if (cardObjects.length > 0) {
          const cardObject = cardObjects[0];
          cardObject.playCard(action);
        }
      }

      if (this.$route.params.type === 'identiteit') {
        this.$refs.buttonContainer.classList.add('disabled-cards-container');
      }
    },
  },
  mounted() {
    if (this.$route.params.type === 'identiteit') {
      this.$refs.buttonContainer.classList.add('disabled-cards-container');
    }
  },
};
</script>

<style lang="sass" scoped>
.button-container
  @include flex-row(center)

.cards-container
  @include flex-row(center)
  & h2
    background-color: white
    border-radius: 10px
    padding: 15px
    position: absolute
    top: 45%

.disabled-cards-container
  opacity: 0.2
  pointer-events: none

.cards
  height: 400px
  margin-top: 30px
  position: relative
  @include xs-max
    width: 275px
    height: 350px
  @include sm
    width: 350px
    height: 500px

</style>
