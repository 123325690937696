<template>
  <div>
    <div class="content-container">
      <div v-for="test in $styleConfiguration.tests" :key="test.id">
        <button
          class="primary-button-orange"
          @click="routeToResults(test.test_type_name)">
          {{test.title}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnknownResultsBody',
  methods: {
    routeToResults(type) {
      this.$router.push({
        name: 'Results',
        // eslint-disable-next-line object-shorthand
        params: { testIdentifier: type },
      });
      this.$emit('updatedResultsPageRoute', type);
    },
  },
};
</script>

<style lang="sass" scoped>
.content-container
  @include flex-column(center)
  @include sm-max
    width: 100vw
  @include md
    width: 100%
  & button
    margin: 20px
  & p
    margin: 20px

.primary-button-orange
  align-self: center
  @include sm-max
    width: 300px
  @include md
    width: 400px
</style>
