<template>
  <div class="test-container centering">
    <transition name="fade" mode="out-in">
      <div class="game-card-stack-container" v-if="!showingExplanation">
        <game-card-stack
          :cards="visibleCards"
          @cardAccepted="handleCardAccepted"
          @cardRejected="handleCardRejected"
          @hideCard="removeCardFromDeck"
        />
        <loading-progress
          :progress="Math.min(this.cardNumberProgress/this.totalNumberOfCards, 1)"
          shape="line"
          size="200"
          width="200"
          height="6"
          color="#F26000"
        />
      </div>

      <swiping-explanation-view
        v-if="showingExplanation"
        :numberOfQuestions="numberOfQuestions"
        @startTestClicked="startTest"
        :cardsLoading="cardsLoading"
      />
    </transition>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable prefer-destructuring */
import GameCardStack from '@/components/tests/swipeableCards/GameCardStack.vue';
import SwipingExplanationView from '@/components/tests/swipeableCards/SwipingExplanationView.vue';

import { QuestionAPI } from '../../../../../Shared/index';

export default {
  name: 'CardsStackGame',
  data() {
    return {
      showingExplanation: true,
      visibleCards: [],
      cardNumberProgress: 0,
      totalNumberOfCards: 0,
      cardsLoading: true,
    };
  },
  components: {
    GameCardStack,
    SwipingExplanationView,
  },
  methods: {
    startTest() {
      this.showingExplanation = false;

      // To make sure the user is at the top of the page when the card swipe
      // shows up, scroll the user to the top of the page
      window.scrollTo(0, 0);
    },
    handleCardAccepted(card) {
      this.cardNumberProgress += 1;
      this.$store.commit(
        'testData/addAnswer',
        {
          qid: card.id,
          answer: true,
        },
      );
    },
    handleCardRejected(card) {
      this.cardNumberProgress += 1;
      this.$store.commit(
        'testData/addAnswer',
        {
          qid: card.id,
          answer: false,
        },
      );
    },
    removeCardFromDeck() {
      this.visibleCards.shift();
      this.checkForAndHandleEmptyCardStack();
    },
    checkForAndHandleEmptyCardStack() {
      if (this.visibleCards.length === 0) {
        const vue = this;
        setTimeout(() => {
          const testName = this.$route.params.type;
          this.$store.commit(
            'testData/setTestName', testName,
          );

          if (testName === 'identiteit') {
            vue.$router.push({ name: 'AnswerOverview' });
          } else {
            vue.$router.push({ name: 'Account' });
          }
        }, 2000);
      }
    },
    shuffleArray(array) {
      const dupArray = array;
      for (let i = dupArray.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * i);
        const temp = dupArray[i];
        dupArray[i] = dupArray[j];
        dupArray[j] = temp;
      }
      return dupArray;
    },
  },
  mounted() {
    this.cardsLoading = true;
    QuestionAPI.getAll(this.$route.params.type)
      .then((snapshot) => {
        let questions = [];
        snapshot.forEach((doc) => {
          questions = doc.data().questions.filter((question) => question.questionActive);
        });

        questions = this.shuffleArray(questions);
        this.totalNumberOfCards = questions.length;
        this.visibleCards = questions;
        this.cardsLoading = false;
      });
  },
  computed: {
    numberOfQuestions() {
      const count = this.visibleCards.length;
      return count;
    },
  },
};
</script>

<style lang="sass" scoped>
.test-container
  @include sm-max
    padding: $standard-margin-size 0

.game-card-stack-container
  @include flex-column(center)
  @include sm-max
    min-height: calc(100vh - #{$footer-height} - 2 * #{$standard-margin-size})
  @include md
    min-height: calc(100vh - #{$footer-height})

.centering
  @include flex-column(center)
  @include sm-max
    min-height: calc(100vh - #{$footer-height} - 2 * #{$standard-margin-size})
  @include md
    min-height: calc(100vh - #{$footer-height})

.fade-enter-active, .fade-leave-active
  transition: opacity 1s

.fade-enter, .fade-leave-to
  opacity: 0

.vue-progress-path
  align-self: center
</style>
