import QuestionSet from '../QuestionSet';
import RiasocQuestion from '../RiasocQuestion';
import TwoSidedImageCardQuestion from '../TwoSidedImageCardQuestion';

const toFirestore = (questionSet) => {
  return {
    questions: questionSet.questions,
    set_name: questionSet.setName,
  };
};

const fromFirestore = (snapshot, options) => {
  const data = snapshot.data(options);

  const questionObjects = [];
  data.questions.forEach((question) => {
    if (question.riasoc_type) {
      questionObjects.push(new RiasocQuestion(
        question.id,
        question.question,
        question.riasoc_type,
        question.question_active,
        question.image_url,
        question.image_alt,
      ));
    } else {
      questionObjects.push(new TwoSidedImageCardQuestion(
        question.id,
        question.question,
        question.question_active,
        question.front_image,
        question.front_image_alt,
        question.back_image,
        question.back_image_alt,
      ));
    }
  });

  return new QuestionSet(
    questionObjects,
    data.set_name,
  );
};


export {
  toFirestore,
  fromFirestore,
};
