export default class AnswerSubmissionLog {
  constructor(answerDocumentId, isUpdateOfAnswers, questionType,
    uploadDate, userEmail, userName, schoolName) {
    this.answerDocumentId = answerDocumentId;
    this.isUpdateOfAnswers = isUpdateOfAnswers;
    this.questionType = questionType;
    this.uploadDate = uploadDate;
    this.userEmail = userEmail;
    this.userName = userName;
    this.schoolName = schoolName;
  }
}
