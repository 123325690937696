import { UserDataAPI } from '../../../../Shared/index';

const getters = {
  loggedIn: (state) => state.loggedIn,
  id: (state) => state.id,
  data: (state) => state.data,
  signInProvider: (state) => state.signInProvider,
  userInformation: (state) => state.userInformation,
};

const mutations = {
  SET_LOGGED_IN(state, value) {
    state.loggedIn = value;
  },
  SET_USER(state, data) {
    state.data = data;
    state.id = data.id;
    state.signInProvider = data.providerData[0].providerId;
  },
  FLUSH_USER(state) {
    state.id = null;
    state.data = null;
    state.signInProvider = null;
    state.userInformation = null;
  },
  SET_USER_DATA(state, data) {
    state.userInformation = data;
  },
};

const actions = {
  fetchUser({ commit }, user) {
    commit('SET_LOGGED_IN', user !== null);

    if (user) {
      commit('SET_USER', {
        id: user.uid,
        displayName: user.displayName,
        email: user.email,
        providerData: user.providerData,
      });
    } else {
      commit('FLUSH_USER');
    }
  },
  loadUserData({ commit, state }) {
    UserDataAPI.get(state.id)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          commit('SET_USER_DATA', doc.data());
        });
      }).catch(() => {
        console.log('[UserDataAPI error]');
      });
  },
};

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    id: null,
    data: null,
    signInProvider: null,
    userInformation: null,
  },
  getters,
  mutations,
  actions,
};
