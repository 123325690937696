export default class RiasocQuestion {
  constructor(id, question, riasocType, questionActive, imageUrl, imageAlt) {
    this.id = id;
    this.question = question;
    this.riasocType = riasocType;
    this.questionActive = questionActive;
    this.imageUrl = imageUrl;
    this.imageAlt = imageAlt;
  }
}
