<template>
  <div class="container">
    <img
      src="@/assets/images/backgrounds/triangle_background.png"
      class="triangle-background-image"
      alt="Achtergrond"
    />

    <div class="header-container">
      <div v-if="!emptyResults && testName !== ''">
        <h1>{{ userInformation.firstName }}
          {{$styleConfiguration.test_results_info[this.testName].header_title}}</h1>
        <div class="result-bubbles-container">
          <div class="result-bubble text-center" v-if="characteristic_1">
            <h1>{{ characteristic_1 }}</h1>
          </div>

          <div class="result-bubble text-center" v-if="characteristic_2">
            <h1>{{ characteristic_2 }}</h1>
          </div>
        </div>
      </div>

      <div v-if="emptyResults">
        <h1>{{ userInformation.firstName }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RiasocResultsHeader',
  data() {
    return {
      characteristic_1: null,
      characteristic_2: null,
      emptyResults: false,
      testName: '',
    };
  },
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
      riasocResults: 'riasocResults/riasocResults',
    }),
  },
  watch: {
    riasocResults(newValue) {
      this.emptyResults = newValue.emptyResults;
      this.characteristic_1 = newValue.characteristic_1;
      this.characteristic_2 = newValue.characteristic_2;
    },
  },
  mounted() {
    this.testName = this.$route.params.testIdentifier;
  },
};
</script>

<style lang="sass" scoped>
.container
  @include flex-column(center)
  align-items: center
  padding: 0 $standard-margin-size
  height: 300px

  & .header-container
    @include flex-column(center)
    & .result-bubbles-container
      @include flex-row(center)

  & .triangle-background-image
    position: absolute
    height: 300px
    width: calc(90vw - 2 * #{$standard-margin-size})
    z-index: -10
</style>
