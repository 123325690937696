const getters = {
  testAnswers: (state) => state.testAnswers,
  testName: (state) => state.testName,
  hideResultsOnComplete: (state) => state.hideResultsOnComplete,
};

const actions = {
  reset(context) {
    context.commit('reset');
  },
};

const mutations = {
  changeSingleAnswer(state, newEntry) {
    const index = state.testAnswers.findIndex((x) => x.qid === newEntry.qid);

    if (index >= 0) {
      state.testAnswers[index] = newEntry;
    }
  },
  reset(state) {
    state.testAnswers = [];
    state.testName = '';
  },
  addAnswer(state, answer) {
    state.testAnswers.push(answer);
  },
  setTestName(state, name) {
    state.testName = name;
  },
  setAnswers(state, answers) {
    state.testAnswers = answers;
  },
  setHideResultsOnComplete(state, hide) {
    state.hideResultsOnComplete = hide;
  },
};

export default {
  namespaced: true,
  state: {
    testAnswers: [],
    testName: '',
    hideResultsOnComplete: false,
  },
  getters,
  mutations,
  actions,
};
