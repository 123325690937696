import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLongArrowAltRight,
  faChevronDown,
  faChevronUp,
  faTachometerAlt,
  faSignInAlt,
  faSignOutAlt,
  faUserCircle,
  faPoll,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'vue-progress-path/dist/vue-progress-path.css';
import VueProgress from 'vue-progress-path';
import Toasted from 'vue-toasted';
import VueScrollTo from 'vue-scrollto';
import VModal from 'vue-js-modal';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import { auth } from '../../Shared/lib/firebase';

library.add(faLongArrowAltRight);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faTachometerAlt);
library.add(faSignInAlt);
library.add(faSignOutAlt);
library.add(faUserCircle);
library.add(faPoll);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueProgress);
Vue.use(Toasted, {
  position: 'bottom-center',
  duration: 4000,
  fullWidth: true,
  className: 'toaster-error',
});
Vue.use(VueScrollTo);
Vue.use(VModal, { dialog: true });

// Turn the following parameters to true in production
// const PRODUCTION = false; in connection.js
Vue.prototype.$production = false;
Vue.config.productionTip = false;

auth.onAuthStateChanged((user) => {
  store.dispatch('user/fetchUser', user);
  if (user) {
    store.dispatch('user/loadUserData');
  }
});

let data = null;
// Load the correct configuration file
switch (window.location.hostname) {
  case 'localhost':
    // eslint-disable-next-line
    data = require('./assets/configurations/karakter.json' /* kiesreis */);
    break;
  case 'test.kiesreis.kieswijs.nl':
    // eslint-disable-next-line
    data = require('./assets/configurations/kiesreis.json');
    break;
  case 'karakter.kieswijs.nl':
    // eslint-disable-next-line
    data = require('./assets/configurations/karakter.json');
    break;
  default:
    break;
}

Vue.prototype.$styleConfiguration = data;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
