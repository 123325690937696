import { usersAnswersCollection } from '../firebase';
import { UserAnswersConverter } from '../../index';

const getAllAnswersForUser = (uid) => {
  return usersAnswersCollection
    .where('user', '==', uid)
    .withConverter(UserAnswersConverter)
    .get();
};

const retrieveAll = (userId, testName) => {
  return usersAnswersCollection
    .where('user', '==', userId)
    .where('question_type', '==', testName)
    .withConverter(UserAnswersConverter)
    .get();
};

const getAnswersWithDocumentId = (documentId) => {
  return usersAnswersCollection
    .doc(documentId)
    .withConverter(UserAnswersConverter)
    .get();
};

const updateQuestionStatus = (documentId, newStatus) => {
  return usersAnswersCollection
    .doc(documentId)
    .update({
      is_visible_for_user: newStatus,
    });
};

const create = (data) => {
  return usersAnswersCollection.add(data);
};

const remove = (documentId) => {
  return usersAnswersCollection.doc(documentId).delete();
};

const update = (documentId, data) => {
  return usersAnswersCollection
    .doc(documentId)
    .update(data);
};

export {
  getAllAnswersForUser,
  getAnswersWithDocumentId,
  updateQuestionStatus,
  retrieveAll,
  create,
  remove,
  update,
};
