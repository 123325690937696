import TwoSidedImageCardQuestion from '../TwoSidedImageCardQuestion';


const toFirestore = (question) => {
  return {
    id: question.id,
    question: question.question,
    question_active: question.questionActive,
    front_image: question.frontImage,
    front_image_alt: question.frontImageAlt,
    back_image: question.backImage,
    back_image_alt: question.backImageAlt,
  };
};

const fromFirestore = (snapshot, options) => {
  const data = snapshot.data(options);
  return new TwoSidedImageCardQuestion(
    data.id,
    data.question,
    data.question_active,
    data.front_image,
    data.front_image_alt,
    data.back_image,
    data.back_image_alt,
  );
};

export {
  toFirestore,
  fromFirestore,
};
