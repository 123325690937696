<template>
  <div class="main-container">
    <background-triangle v-if="testName === 'unk'"/>
    <div class="results-container">
      <div class="header-container">
        <!-- Present the correct container -->
        <riasoc-results-header v-if="testName.includes('riasoc_')"/>
        <identiteit-results-header v-if="testName === 'identiteit'"/>
        <waarden-results-header v-if="testName === 'waardenspel'"/>
        <unknown-results-header v-if="testName === 'unk'"/>
      </div>

      <transition name="fade" mode="out-in">
        <div class="body-container text-center" v-if="fullyLoaded">
          <!-- Present the correct body -->
          <riasoc-results-body
            v-if="testName.includes('riasoc_') && !emptyResults &&
              (isVisibleForUser || $configureStyles.variant === 'kiesreis')"/>
          <identiteit-results-body
            v-if="testName === 'identiteit' && !emptyResults && isVisibleForUser"/>
          <waarden-results-body
            v-if="testName === 'waardenspel' && !emptyResults && isVisibleForUser"/>
          <unknown-results-body
            v-if="testName === 'unk'"
            @updatedResultsPageRoute="reloadAnswers"/>

          <!-- If the test restults are empty -->
          <div v-if="emptyResults && isVisibleForUser && testName !== 'unk'">
            <h1>Je hebt de test nog niet gedaan!</h1>
            <h4>
              Het kan ook zijn dat er niet genoeg resultaten
              zijn om een score te geven.
            </h4>
            <h3>Doe nu de test!</h3>
            <button
              class="primary-button-orange"
              @click="$router.push({name: 'TestEnvironment', params: { type: testName}})"
            >
              Start de test
            </button>
          </div>

          <!-- If the test is not yet made available by the coach -->
          <div v-if="!isVisibleForUser && testName !== 'unk'">
            <h1>Nog even wachten!</h1>
            <h4>
              Jouw Kieswijs adviseur zal zo de resultaten aan je bekend maken!
            </h4>
            <button class="primary-button-blue" @click="reloadAnswers()">
              Druk op deze knop wanneer jouw Kieswijs advieur dit zegt!
            </button>
          </div>

          <!-- Back to main menu -->
          <button
            v-if="(testName.includes('riasoc_')
              || testName === 'identiteit'
              || testName === 'waardenspel')"
            class="secondary-button-blue back-button"
            @click="$router.push({ name: 'Results', params: { testIdentifier: 'unk' } });"
          >
            Ga terug naar het resultaten hoofdmenu
          </button>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div v-if="!fullyLoaded" class="loading-container">
          <scale-loader color="#F26000"></scale-loader>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';

import RiasocResultsHeader from '@/components/results/riasoc/RiasocResultsHeader.vue';
import RiasocResultsBody from '@/components/results/riasoc/RiasocResultsBody.vue';

import IdentiteitResultsHeader from '@/components/results/identiteit/IdentiteitResultsHeader.vue';
import IdentiteitResultsBody from '@/components/results/identiteit/IdentiteitResultsBody.vue';

import WaardenResultsHeader from '@/components/results/waardenspel/WaardenResultsHeader.vue';
import WaardenResultsBody from '@/components/results/waardenspel/WaardenResultsBody.vue';

import UnknownResultsHeader from '@/components/results/unknown/UnknownResultsHeader.vue';
import UnknownResultsBody from '@/components/results/unknown/UnknownResultsBody.vue';

import BackgroundTriangle from '@/components/BackgroundTriangle.vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

export default {
  name: 'Results',
  data() {
    return {
      isVisibleForUser: false,
      emptyResults: false,
      fullyLoaded: false,
    };
  },
  methods: {
    loadData() {
      this.fullyLoaded = false;
      this.isVisibleForUser = false;

      if (this.testName.includes('riasoc_')) {
        this.$store.dispatch(
          'riasocResults/retrieveData',
          {
            userId: this.userId,
            testName: this.testName,
          },
        );
      } else if (this.testName === 'identiteit') {
        this.$store.dispatch('identiteitResults/retrieveData', this.userId);
      } else if (this.testName === 'waardenspel') {
        this.$store.dispatch(
          'waardenResults/retrieveData',
          {
            userId: this.userId,
            testName: this.testName,
          },
        );
      } else if (this.testName === 'unk') {
        this.emptyResults = false;
        this.fullyLoaded = true;
      }
    },
    reloadAnswers() {
      this.loadData();
    },
  },
  components: {
    RiasocResultsHeader,
    RiasocResultsBody,
    BackgroundTriangle,
    IdentiteitResultsHeader,
    IdentiteitResultsBody,
    WaardenResultsHeader,
    WaardenResultsBody,
    UnknownResultsHeader,
    UnknownResultsBody,
    ScaleLoader,
  },
  computed: {
    ...mapGetters({
      userId: 'user/id',

      riasocResults: 'riasocResults/riasocResults',
      identiteitResults: 'identiteitResults/identiteitResults',
      waardenResults: 'waardenResults/waardenResults',

      identiteitIsVisibleForUser: 'identiteitResults/isVisibleForUser',
      riasocIsVisibleForUser: 'riasocResults/isVisibleForUser',
      waardenIsVisibleForUser: 'waardenResults/isVisibleForUser',
    }),
    testName() {
      return this.$route.params.testIdentifier;
    },
  },
  watch: {
    riasocResults(newValue) {
      if (this.testName.includes('riasoc_')) {
        this.emptyResults = newValue.emptyResults;
        this.isVisibleForUser = newValue.isVisibleForUser;
        this.fullyLoaded = true;
      }
    },
    identiteitResults(newValue) {
      if (this.testName === 'identiteit') {
        this.emptyResults = newValue.emptyResults;
        this.isVisibleForUser = newValue.isVisibleForUser;
        this.fullyLoaded = true;
      }
    },
    waardenResults(newValue) {
      if (this.testName === 'waardenspel') {
        this.emptyResults = newValue.emptyResults;
        this.isVisibleForUser = newValue.isVisibleForUser;
        this.fullyLoaded = true;
      }
    },
  },
  mounted() {
    // Route Guards
    switch (this.$styleConfiguration.variant) {
      case 'kiesreis':
        // Only riasoc or unknown are allowed
        if (!(this.testName === 'riasoc_kiesreis' || this.testName === 'unk')) {
          this.$router.push({
            name: 'Results',
            // eslint-disable-next-line object-shorthand
            params: { testIdentifier: 'unk' },
          });
        }
        break;
      default:
        // For karakter, all views are allowed
        break;
    }

    // If all is OK, we load the data
    this.loadData();
  }
};
</script>

<style lang="sass" scoped>

.main-container
  @include flex-column(center)
  align-items: center

.results-container
  @include flex-column(center)
  min-height: calc(100vh - #{$footer-height})
  max-width: 1200px

.header-container
  & h1
    padding: 10px
    text-align: center

.back-button
  margin: 2em 0

.loading-container
  @include flex-column(center)
  height: 50vh
</style>
