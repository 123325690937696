<template>
  <div class="triangle-background" ref="triangleBackground">
    <svg class="triangle-background-svg-container" viewBox="0 0 100 100" preserveAspectRatio="none">
      <polygon class="polygon" points="0 0, 100 50, 0 100" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BackgroundTriangle',
  methods: {
    configureStyles() {
      const config = this.$styleConfiguration;
      this.$refs.triangleBackground.style.fill = config.light_gray_hex;
    },
  },
  mounted() {
    this.configureStyles();
  },
};
</script>

<style scoped lang="sass">
.triangle-background
  position: absolute
  @include flex-column(center)
  align-items: center
  height: 100vh
  width: 100vw
  z-index: -10

.triangle-background-svg-container
  @include sm-max
    margin-left: 2vw
    width: 95vw
  @include md
    margin-left: 5vw
    width: 70vw
</style>
