import { render, staticRenderFns } from "./TestsBody.vue?vue&type=template&id=3410ea76&scoped=true&"
import script from "./TestsBody.vue?vue&type=script&lang=js&"
export * from "./TestsBody.vue?vue&type=script&lang=js&"
import style0 from "./TestsBody.vue?vue&type=style&index=0&id=3410ea76&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3410ea76",
  null
  
)

export default component.exports