import { storage } from '../firebase';

const listFiles = (directory) => {
  const storageRef = storage.ref();
  return storageRef.child(directory).listAll();
};

const downloadFile = (path) => {
  const storageRef = storage.ref(path);
  return storageRef.getDownloadURL();
};

const deleteFile = (path) => {
  const storageRef = storage.ref();
  return storageRef.child(path).delete();
};


export {
  listFiles,
  downloadFile,
  deleteFile,
};
