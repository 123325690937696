<template>
    <div
      class="blue-container-rounded-corners test-item-container"
      :ref="`testContainer_${testTypeName}`"
    >
        <h3>{{ title }}</h3>
        <div class="test-item-text-body-container">
            <p class="description-label">{{ description }}</p>
            <p class="time-label">{{ duration }} minuten</p>
        </div>

        <button
            @click="startWorkshop"
            class="workshop-button"
        >
            <svg viewBox="0 0 48 48" class="play-button-icon">
                <circle id="Oval" fill="#F26000" cx="23.625" cy="23.625" r="23.625"></circle>
                <polygon
                    id="Triangle"
                    fill="#FFFFFF"
                    transform="translate(25.125000, 23.625000)
                        rotate(90.000000) translate(-25.125000, -23.625000)"
                    points="25.125 15.75 33 31.5 17.25 31.5"
                >
                </polygon>
            </svg>
            <p>Start hier als je deelnemer bent van een Kieswijs workshop</p>
        </button>

        <!-- Temporarily remove the button with which you directly see your results -->
        <!-- <button
            @click="startNormalRun"
        >
            <svg width="48px" height="48px" viewBox="0 0 48 48" >
                <circle id="Oval" fill="#0B7195" cx="23.625" cy="23.625" r="23.625"></circle>
                <polygon
                    id="Triangle"
                    fill="#FFFFFF"
                    transform="translate(25.125000, 23.625000)
                        rotate(90.000000) translate(-25.125000, -23.625000)"
                    points="25.125 15.75 33 31.5 17.25 31.5"
                >
                </polygon>
            </svg>
            Start nu
        </button> -->
    </div>
</template>

<script>
export default {
  name: 'TestItemContainer',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    testTypeName: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.configureStyles();
  },
  methods: {
    configureStyles() {
      this.$refs[`testContainer_${this.testTypeName}`].style.backgroundColor = this.$styleConfiguration.light_gray_hex;
    },
    startNormalRun() {
      this.$store.commit('testData/setHideResultsOnComplete', false);
      this.$router.push({ name: this.routeName, params: { type: this.testTypeName } });
    },
    startWorkshop() {
      this.$store.commit('testData/setHideResultsOnComplete', true);
      this.$router.push({ name: this.routeName, params: { type: this.testTypeName } });
    },
  },
};
</script>

<style scoped lang="sass">
.test-item-container
    transition: transform 0.2s linear
    width: 100%
    min-width: 200px
    padding: 1.5em !important

    &:hover
      transform: scale(1.02)

    & h3
        background-color: white
        border-radius: 5px
        padding: 1em

    & .test-item-text-body-container
        padding: 0 1em
        width: 70%
        & p
            font-size: 0.8em
        & .description-label
            @include sm-max
                height: 100px
            @include md
                height: 80px
        & .time-label
            font-weight: bold
            opacity: 0.5

    & button
        @include basicButton
        @include flex-row(flex-start)
        align-items: center
        background-color: transparent
        color: $blue
        font-size: 1em
        margin-top: 1.5em
        padding: 0
        text-align: left
        & svg
            margin-right: .5em
.workshop-button
  color: $orange !important

.play-button-icon
  max-width: 48px
</style>
