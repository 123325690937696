import { AnswerAPI, QuestionAPI } from '../../../../Shared/index';

const getters = {
  riasocResults: (state) => ({
    riasoc_counts: state.riasoc_counts,
    emptyResults: state.emptyResults,
    characteristic_1: state.characteristic_1,
    characteristic_2: state.characteristic_2,
    isVisibleForUser: state.isVisibleForUser,
  }),
  isVisibleForUser: (state) => state.isVisibleForUser,
};

const actions = {
  retrieveData(context, { userId, testName }) {
    QuestionAPI.getAll(testName)
      .then((snapshot) => {
        const questions = new Map();

        snapshot.forEach((doc) => {
          doc.data().questions.forEach((question) => {
            questions.set(question.id, question);
          });
        });

        AnswerAPI.retrieveAll(userId, testName)
          .then((answerSnapshot) => {
            const trueTypes = [];

            answerSnapshot.forEach((doc) => {
              if (!doc.data().isVisibleForUser) {
                context.commit('hideResultsFromUser');
                return;
              }
              context.commit('enableResultsForUser');
              const answers = doc.data().allAnswers;
              answers.forEach((answer) => {
                if (answer.answer) {
                  trueTypes.push(questions.get(answer.qid).riasocType);
                }
              });
            });

            context.commit('setScoreData', trueTypes);
          });
      });
  },
  flushData({ commit }) {
    commit('FLUSH_DATA');
  },
};

const mutations = {
  hideResultsFromUser(state) {
    state.isVisibleForUser = false;
  },
  enableResultsForUser(state) {
    state.isVisibleForUser = true;
  },
  FLUSH_DATA(state) {
    state.riasoc_counts = 0;
    state.emptyResults = false;
    state.characteristic_1 = '';
    state.characteristic_2 = '';
  },
  setScoreData(state, data) {
    let scoreMap = new Map([
      ['R', 0],
      ['I', 0],
      ['A', 0],
      ['S', 0],
      ['O', 0],
      ['C', 0],
    ]);

    data.forEach((categoryId) => {
      scoreMap.set(categoryId, scoreMap.get(categoryId) + 1);
    });

    scoreMap = new Map([...scoreMap.entries()].sort((a, b) => {
      if (a[1] < b[1]) return 1;
      return -1;
    }));
    const scores = [...scoreMap.entries()];

    state.riasoc_counts = scoreMap;
    state.emptyResults = data.length === 0;

    // eslint-disable-next-line prefer-destructuring
    state.characteristic_1 = scores[0][0];
    // eslint-disable-next-line prefer-destructuring
    state.characteristic_2 = scores[1][0];
  },
};

export default {
  namespaced: true,
  state: {
    riasoc_counts: 0,
    emptyResults: false,
    characteristic_1: '',
    characteristic_2: '',
    isVisibleForUser: false,
  },
  getters,
  mutations,
  actions,
};
