<template>
  <div class="home-container">
    <div>
      <home-hero/>
      <tests-body id="test-body"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHero from '@/components/home/HomeHero.vue';
import TestsBody from '@/components/home/TestsBody.vue';

export default {
  name: 'Home',
  components: {
    HomeHero,
    TestsBody,
  },
};
</script>


<style scoped lang="sass">
.home-container
  @include flex-column(space-between)
  min-height: calc(100vh - #{$footer-height})
</style>
