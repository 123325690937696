<template>
    <div class="footer-container">
      <p>© Kieswijs 2023 (V1.12) - Website:</p>
      <a href="https://marcw1g.github.io/" target="_blank">Marc Wiggerman</a>
    </div>
</template>

<script>
export default {
  name: 'InfoFooter',
  components: {
  },
};
</script>

<style scoped lang="sass">
.footer-container
  @include flex-row(center)
  font-size: 0.7em
  align-items: center
  background-color: $dark-gray
  color: $text-gray
  height: $footer-height
  width: 100vw
  & a
    color: $text-light-gray
    margin-left: 4px
    text-decoration: none
    &:visited
      color: $text-light-gray
</style>
