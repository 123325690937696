import { AnswerAPI, QuestionAPI } from '../../../../Shared/index';

const getters = {
  identiteitResults: (state) => ({
    answered_true: state.answered_true,
    answered_false: state.answered_false,
    emptyResults: state.emptyResults,
    isVisibleForUser: state.isVisibleForUser,
  }),
  isVisibleForUser: (state) => state.isVisibleForUser,
};

const actions = {
  retrieveData(context, userId) {
    QuestionAPI.getAll('identiteit')
      .then((snapshot) => {
        const questions = new Map();

        snapshot.forEach((doc) => {
          doc.data().questions.forEach((question) => {
            questions.set(question.id, question);
          });
        });

        AnswerAPI.retrieveAll(userId, 'identiteit')
          .then((answerSnapshot) => {
            if (answerSnapshot.empty) {
              context.commit('setScoreData', {
                answeredTrue: [],
                answeredFalse: [],
              });
              return;
            }

            answerSnapshot.forEach((doc) => {
              const identiteitAnswers = {
                answeredTrue: [],
                answeredFalse: [],
              };

              if (!doc.data().isVisibleForUser) {
                context.commit('hideResultsFromUser');
                return;
              }

              const answers = doc.data().allAnswers;
              answers.forEach((answer) => {
                if (answer.answer) {
                  identiteitAnswers.answeredTrue.push({
                    answer: answer,
                    question: questions.get(answer.qid),
                    answerSetId: doc.id,
                  });
                } else {
                  identiteitAnswers.answeredFalse.push({
                    answer: answer,
                    question: questions.get(answer.qid),
                    answerSetId: doc.id,
                  });
                }
              });

              context.commit('setScoreData', identiteitAnswers);
            });
          });
      });
  },
  updateSingleAnswer(context, questionAndAnswer) {
    context.commit('changeAnswer', questionAndAnswer);
  },
  flushData({ commit }) {
    commit('FLUSH_DATA');
  },
};

const mutations = {
  hideResultsFromUser(state) {
    state.isVisibleForUser = false;
    state.answered_true = [];
    state.answered_false = [];
    state.emptyResults = true;
  },
  setScoreData(state, response) {
    state.answered_true = response.answeredTrue;
    state.answered_false = response.answeredFalse;

    state.emptyResults = state.answered_true.length === 0
        && state.answered_false.length === 0;
    state.isVisibleForUser = true;
  },
  changeAnswer(state, answer) {
    let indexOfOldAnswer;
    if (answer.oldAnswer === true) {
      indexOfOldAnswer = state.answered_true.findIndex(
        (element) => element.answer.qid === answer.qid,
      );
      const oldAnswerObject = state.answered_true[indexOfOldAnswer];
      oldAnswerObject.answer.answer = false;
      state.answered_true.splice(indexOfOldAnswer, 1);
      state.answered_false.push(oldAnswerObject);
    } else {
      indexOfOldAnswer = state.answered_false.findIndex(
        (element) => element.answer.qid === answer.qid,
      );
      const oldAnswerObject = state.answered_false[indexOfOldAnswer];
      oldAnswerObject.answer.answer = true;
      state.answered_false.splice(indexOfOldAnswer, 1);
      state.answered_true.push(oldAnswerObject);
    }
  },
  FLUSH_DATA(state) {
    state.answered_true = [];
    state.answered_false = [];
    state.emptyResults = true;
  },
};

export default {
  namespaced: true,
  state: {
    answered_true: [],
    answered_false: [],
    emptyResults: true,
    isVisibleForUser: false,
  },
  getters,
  mutations,
  actions,
};
