<template>
  <div>
    <div class="header-container">
      <div>
        <h1 v-if="userInformation">{{ userInformation.firstName }},
          van welke test wil jij<br/>je resultaten zien?</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UnknownResultsHeader',
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
    }),
  },
};
</script>

<style lang="sass" scoped>
h1
  text-align: center
  @include sm-max
    text-align: center
    margin: 0 20px
</style>
