import { userInformationCollection } from '../firebase';
import { UserDataConverter } from '../../index';

const get = (uid) => {
  return userInformationCollection
    .where('uid', '==', uid)
    .withConverter(UserDataConverter)
    .get();
};

const getUserUsingData = (data) => {
  let baseRequest = userInformationCollection;

  const {
    firstName,
    lastName,
    schoolName,
    className,
  } = data;

  if (firstName) {
    baseRequest = baseRequest.where('firstNameLowercase', '==', firstName.toLowerCase());
  }

  if (lastName) {
    baseRequest = baseRequest.where('lastNameLowercase', '==', lastName.toLowerCase());
  }

  if (schoolName) {
    baseRequest = baseRequest.where('schoolName', '==', schoolName.toLowerCase());
  }

  if (className) {
    baseRequest = baseRequest.where('className', '==', className.toLowerCase());
  }

  return baseRequest.withConverter(UserDataConverter)
    .get();
};

const update = (uid, updates) => {
  return userInformationCollection
    .doc(uid)
    .update(updates);
};

const getParentsForUserWithKieswijsID = (kieswijsCode) => {
  return userInformationCollection
    .where('childKieswijsCodes', 'array-contains', kieswijsCode)
    .withConverter(UserDataConverter)
    .get();
};

const create = (data) => {
  return userInformationCollection
    .withConverter(UserDataConverter)
    .add(data);
};

const remove = (documentId) => {
  return userInformationCollection
    .doc(documentId).delete();
};

export {
  get,
  getUserUsingData,
  update,
  getParentsForUserWithKieswijsID,
  create,
  remove,
};
