<template>
  <div class="chart-legend-container">
    <div class="chart-container">
      <chart-row
        v-for="(data, index) in chartData"
        :index="index"
        :value="data.value"
        :highScore="maxScore"
        :title="data.title"
        :key="data.title"
        :description="groupDescriptions[data.title[0]]"
        @hoverOverBar="hoverRow(index)"
        :ref="`row${index}`"
      />
    </div>

    <div class="legend-container">
      <img src="@/assets/images/pointer-arrow-left.png" alt="Poiter Arrow" ref="pointerArrow">
      <div class="legend-text-container">
        <p>{{ informationText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ChartRow from '@/components/chart/ChartRow.vue';

export default {
  name: 'Chart',
  props: {
    riasocCounts: {
      type: Map,
      required: true,
    },
  },
  methods: {
    hoverRow(dataIndex) {
      const riasocType = this.chartData[dataIndex].title[0];
      this.informationText = this.groupDescriptions[riasocType];
      const arrow = this.$refs.pointerArrow;
      arrow.style.top = `${(dataIndex * 40) + ((dataIndex + 1) * 20)}px`;
    },
    compareRiasocScore(a, b) {
      if (a.value > b.value) {
        return -1;
      }
      return 1;
    },
  },
  data() {
    return {
      chartData: [],
      riasocTitles: {
        R: this.$styleConfiguration.riasoc_titles.R,
        I: this.$styleConfiguration.riasoc_titles.I,
        A: this.$styleConfiguration.riasoc_titles.A,
        S: this.$styleConfiguration.riasoc_titles.S,
        O: this.$styleConfiguration.riasoc_titles.O,
        C: this.$styleConfiguration.riasoc_titles.C,
      },
      groupDescriptions: {
        R: this.$styleConfiguration.riasoc_descriptions.R,
        I: this.$styleConfiguration.riasoc_descriptions.I,
        A: this.$styleConfiguration.riasoc_descriptions.A,
        S: this.$styleConfiguration.riasoc_descriptions.S,
        O: this.$styleConfiguration.riasoc_descriptions.O,
        C: this.$styleConfiguration.riasoc_descriptions.C,
      },
      informationText: '',
      maxScore: 0.0,
    };
  },
  mounted() {
    const scores = [];
    const rawScores = [];

    this.riasocCounts.forEach((value, key) => {
      scores.push({
        title: this.riasocTitles[key],
        value,
      });
      rawScores.push(value);
    });

    this.maxScore = Math.max(...rawScores);
    this.chartData = scores;
    this.informationText = this.groupDescriptions[scores[0].title[0]];
  },
  components: {
    ChartRow,
  },
};
</script>

<style lang="sass">
.chart-legend-container
  @include flex-row(center)

.chart-container
  padding: $chart-standard-padding

.legend-container
  @include md-max
    display: none
  @include lg
    @include flex-row(center)
    flex-grow: 1

  & img
    height: $chart-bar-height
    position: relative
    top: 20px
    -webkit-transition: 0.2s ease-out
    transition: 0.2s ease-out
    width: $chart-standard-padding

  & .legend-text-container
    @include flex-column(center)
    border: 8px solid $orange
    border-radius: 10px
    height: 340px
    max-width: 250px
    margin: 0
    padding: $chart-standard-padding

    & p
      font-size: .9em
</style>
