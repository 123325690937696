<template>
  <div class="content-container">
    <scale-loader v-if="chartLoading" color="#F26000"></scale-loader>

    <chart
      v-if="!chartLoading"
      :riasocCounts="this.riasoc_counts"
    />
  </div>
</template>

<script>
import Chart from '@/components/chart/Chart.vue';

import { mapGetters } from 'vuex';
// eslint-disable-next-line import/extensions
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

export default {
  name: 'RiasocResultsBody',
  data() {
    return {
      chartLoading: true,
      riasoc_counts: null,

      characteristic_1: null,
      characteristic_2: null,

      typeLinkToTravels: {
        R: 'https://google.com',
        I: 'https://google.com',
        A: 'https://google.com',
        S: 'https://google.com',
        O: 'https://google.com',
        C: 'https://google.com',
      },
      riasocFullTitles: {
        R: 'Realist',
        I: 'Inhoudelijk',
        A: 'Artistiek',
        S: 'Sociaal',
        O: 'Ondernemend',
        C: 'Conventioneel',
      },
    };
  },
  methods: {
    checkToFillChart(data) {
      if (data === undefined) return;

      this.riasoc_counts = data.riasoc_counts;
      this.characteristic_1 = data.characteristic_1;
      this.characteristic_2 = data.characteristic_2;
      this.chartLoading = false;
    },
  },
  components: {
    Chart,
    ScaleLoader,
  },
  computed: {
    ...mapGetters({
      riasocResults: 'riasocResults/riasocResults',
    }),
  },
  mounted() {
    this.chartLoading = true;
    this.checkToFillChart(this.riasocResults);
  },
  watch: {
    riasocResults(newValue) {
      this.checkToFillChart(newValue);
    },
  },
};
</script>

<style lang="sass" scoped>
a
  margin: 10px  !important
  text-decoration: none

.travel-buttons-container
  @include sm-max
    @include flex-column(space-between)
  @include md
    @include flex-row(space-between)
    flex-grow: 1

.content-container
  @include flex-column(center)
  padding: 0 $standard-margin-size
  @include sm-max
    width: 100vw
  & button
    margin: 20px
  & p
    margin: 20px

.primary-button-orange
  flex-basis: 100%
</style>
