<template>
  <div class="main-container">
    <div>
      <background-triangle/>

      <div class="content-container">
        <div class="header-container">
          <h1 class="big-title">
            Check hieronder nog een keer jouw antwoorden:
          </h1>
        </div>

        <div class="body-container">
          <IdentiteitCardsResults
            :results="preparedResults"
            :answersEditable="true"
            @changeCurrentAnswer="changeCurrentIdentiteitAnswer"/>
          <button
            class="primary-button-orange standard-button-shadow"
            style="margin-bottom: 1em;"
            @click="routeToLogin">
            Antwoorden opslaan
          </button>
        </div>
      </div>
    </div>
    <scroll-down-hint/>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import BackgroundTriangle from '@/components/BackgroundTriangle.vue';
import IdentiteitCardsResults from '@/components/results/identiteit/IdentiteitCardsResults.vue';
import ScrollDownHint from '../components/ScrollDownHint.vue';

import { QuestionAPI } from '../../../Shared/index';

export default {
  name: 'AnswerOverview',
  data() {
    return {
      preparedResults: null,
    };
  },
  mounted() {
    this.prepareData();
  },
  methods: {
    prepareData() {
      if (this.testName === 'identiteit') {
        this.prepareIdentiteit();
      }
    },
    prepareIdentiteit() {
      QuestionAPI.getAll('identiteit')
      .then((snapshot) => {
        const questions = new Map();

        snapshot.forEach((doc) => {
          doc.data().questions.forEach((question) => {
            questions.set(question.id, question);
          });
        });

        const identiteitAnswers = {
          answeredTrue: [],
          answeredFalse: [],
        };

        this.testAnswers.forEach((answer) => {
          if (answer.answer) {
            identiteitAnswers.answeredTrue.push({
              answer: answer,
              question: questions.get(answer.qid),
            });
          } else {
            identiteitAnswers.answeredFalse.push({
              answer: answer,
              question: questions.get(answer.qid),
            });
          }
        });

        this.preparedResults = identiteitAnswers;
      });
    },
    changeCurrentIdentiteitAnswer(newAnswer) {
      if (newAnswer.answer) {
        // It was false
        const oldIndex = this.preparedResults.answeredFalse.findIndex((x) => x.answer.qid === newAnswer.qid);
        if (oldIndex > -1) {
          let oldAnswer = this.preparedResults.answeredFalse[oldIndex];
          this.preparedResults.answeredFalse.splice(oldIndex, 1);

          const newA = {
            answer: newAnswer,
            question:  oldAnswer.question,
          }

          this.preparedResults.answeredTrue.push(newA);
        }
      } else {
        // It was true
        const oldIndex = this.preparedResults.answeredTrue.findIndex((x) => x.answer.qid === newAnswer.qid);
        if (oldIndex > -1) {
          let oldAnswer = this.preparedResults.answeredTrue[oldIndex];
          this.preparedResults.answeredTrue.splice(oldIndex, 1);

          const newA = {
            answer: newAnswer,
            question:  oldAnswer.question,
          }

          this.preparedResults.answeredFalse.push(newA);
        }
      }

      this.$store.commit(
        'testData/changeSingleAnswer',
        newAnswer,
      );

      this.$toasted.show('Het antwoord is veranderd!');
    },
    routeToLogin() {
      this.$router.push({ name: 'Account' });
    },
  },
  components: {
    BackgroundTriangle,
    IdentiteitCardsResults,
    ScrollDownHint,
  },
  computed: {
    ...mapGetters({
      userId: 'user/id',
      testAnswers: 'testData/testAnswers',
      testName: 'testData/testName',
    }),
  },
};
</script>

<style lang="sass" scoped>
.content-container
  @include flex-column(center)
  @include sm-max
    width: 80vw
    margin: 10vw
  min-height: calc(100vh - #{$footer-height})

.header-container
  @include flex-column(center)
  margin-top: 4em
  align-items: center

.body-container
  @include flex-column(center)
  margin: 0 5em
</style>
