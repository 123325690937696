<template>
  <div class="">
    <background-triangle/>

    <div class="content-container">
      <div class="header-container">
        <h1 class="big-title">
          Log in en check jouw resultaten!
        </h1>
      </div>

      <div class="body-container text-center">
        <div class="column" v-if="!isLoading">
          <div class="column" v-if="!loggedIn">
              <button
                class="primary-button-orange standard-button-shadow sign-in-button"
                @click="signIn">
                Inloggen bij Mijn Kieswijs!
              </button>
            </div>

            <div class="column" v-if="loggedIn">
              <scale-loader color="#F26000"></scale-loader>
            </div>
        </div>

        <div class="column loading-column" v-if="isLoading">
          <scale-loader color="#F26000"></scale-loader>
        </div>

        <div class="column hide-on-small-screen">
          <img :src="getRightImage" alt="Account">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundTriangle from '@/components/BackgroundTriangle.vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import { mapGetters } from 'vuex';

import riasocImage from '@/assets/images/home/account-image.png';
import identitietImage from '@/assets/images/home/identitiet-image.png';

import { firestore } from '../../../Shared/lib/firebase';

import { AnswerAPI, AnswerSubmissionLogAPI } from '../../../Shared/index';
import { Authentication } from '../../../Shared/lib/helpers/Authentication';

export default {
  name: 'Account',
  data: () => {
    return {
      isLoading: false,
    };
  },
  methods: {
    signIn() {
      this.isLoading = true;
      Authentication.authenticateIfRequired()
        .then(() => {
          // The authentication might take some time, so we wait with removing
          // the loading screen
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch(() => {
          console.log('Sign in error');
          this.isLoading = false;
        });
    },
    storeAnswers() {
      if (this.$store.state.testData.testAnswers.length > 0
        || ('selected' in this.$store.state.testData.testAnswers && this.$store.state.testData.testAnswers.selected.length > 0)) {
        let testIdentifier = this.$store.state.testData.testName;

        if (testIdentifier === null || testIdentifier === '' || testIdentifier === undefined) {
          testIdentifier = 'unk';
          this.$store.dispatch('testData/reset');
          this.$router.push({
            name: 'Results',
            // eslint-disable-next-line object-shorthand
            params: { testIdentifier: testIdentifier },
          });
          return;
        }

        AnswerAPI.retrieveAll(this.$store.state.user.id, testIdentifier)
          .then((snapshot) => {
            if (snapshot.empty) {
              this.storeAnswersAsNew(testIdentifier);
            } else {
              snapshot.forEach((doc) => {
                this.storeAnswersAsUpdate(testIdentifier, doc.id);
              });
            }
          })
          .catch(() => {
            this.$toasted.error('Er is iets fout gegeaan tijdens het opslaan van de test data');
          });
      } else {
        this.$router.push({
          name: 'Results',
          params: { testIdentifier: 'unk' },
        });
      }
    },
    storeAnswersAsNew(testIdentifier) {
      AnswerAPI.create({
        user: this.$store.state.user.id,
        all_answers: this.$store.state.testData.testAnswers,
        question_type: testIdentifier,
        upload_date: firestore.FieldValue.serverTimestamp(),
        is_visible_for_user: this.shouldBeStandardVisible(testIdentifier),
      })
        .then((docRef) => {
          AnswerSubmissionLogAPI.create({
            user_name: this.$store.state.user.data.displayName,
            user_email: this.$store.state.user.data.email,
            upload_date: firestore.FieldValue.serverTimestamp(),
            answer_document_id: docRef.id,
            question_type: testIdentifier,
            is_update_of_answers: false,
            school_name: this.$store.state.user.userInformation.schoolName,
          }).then(() => {
            this.$toasted.success('De test data is nu opgeslagen!');
            this.$store.dispatch('testData/reset');
            this.$router.push({
              name: 'Results',
              // eslint-disable-next-line object-shorthand
              params: { testIdentifier: testIdentifier },
            });
          });
        })
        .catch(() => {
          this.$store.dispatch('testData/reset');
          console.log('(A0001) Error');
          this.$toasted.error('Er is iets fout gegaan (A0001)');
        });
    },
    storeAnswersAsUpdate(testIdentifier, answerSubmissionId) {
      AnswerAPI.update(answerSubmissionId, {
        all_answers: this.$store.state.testData.testAnswers,
        upload_date: firestore.FieldValue.serverTimestamp(),
        is_visible_for_user: this.shouldBeStandardVisible(testIdentifier),
      })
        .then(() => {
          const userName = `${
            this.$store.state.user.userInformation.firstName
          } ${this.$store.state.user.userInformation.lastName}`;
          AnswerSubmissionLogAPI.create({
            user_name: userName,
            user_email: this.$store.state.user.data.email,
            upload_date: firestore.FieldValue.serverTimestamp(),
            answer_document_id: answerSubmissionId,
            question_type: testIdentifier,
            is_update_of_answers: true,
            school_name: this.$store.state.user.userInformation.schoolName,
          }).then(() => {
            this.$toasted.success('De test data is nu opgeslagen!');

            this.$store.dispatch('testData/reset');
            this.$router.push({
              name: 'Results',
              // eslint-disable-next-line object-shorthand
              params: { testIdentifier: testIdentifier },
            });
          });
        })
        .catch(() => {
          this.$store.dispatch('testData/reset');
          console.log('(A0002) Error');
          this.$toasted.error('Er is iets fout gegaan (A0002)');
        });
    },
    // eslint-disable-next-line
    shouldBeStandardVisible(testIdentifier) {
      // Only RIASOC Kiesreis & Identiteit should be visible initially
      // return testIdentifier.includes('riasoc_kiesreis') || testIdentifier.includes('identiteit');

      // Hiding the result is based on the flow taken at the start of the run
      return !this.$store.state.testData.hideResultsOnComplete;
    },
  },
  components: {
    BackgroundTriangle,
    ScaleLoader,
  },
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
    }),
    getRightImage() {
      const testIdentifier = this.$store.state.testData.testName;
      if (testIdentifier === null || testIdentifier === '' || testIdentifier === 'identiteit') {
        return identitietImage;
      }
      return riasocImage;
    },
  },
  watch: {
    loggedIn(value) {
      if (value) {
        this.storeAnswers();
      }
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.storeAnswers();
    }
  },
};
</script>

<style lang="sass" scoped>
.content-container
  @include flex-column(center)
  @include sm-max
    width: 80vw
    margin: 10vw
  min-height: calc(100vh - #{$footer-height})

.header-container
  @include flex-column(center)
  align-items: center

.body-container
  margin: 0 5em
  @include md
    @include flex-row(center)

.column
  @include flex-column(center)
  min-height: 30vh
  & img
    @include sm-max
      display: none
    height: 175px
    width: 175px
  @include md
    margin-right: 20px

.loading-column
  width: 175px

.sign-in-button
  font-size: 1em
  height: 100px
</style>
