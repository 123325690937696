
import TestEnvironment from '@/views/TestEnvironment.vue';
import AnswerOverview from '@/views/AnswerOverview.vue';
import Account from '@/views/Account.vue';
import Results from '@/views/Results.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import ErrorPage from '@/views/ErrorPage.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  }, {
    path: '/test-environment/:type',
    name: 'TestEnvironment',
    component: TestEnvironment,
  }, {
    path: '/antwoorden-overzicht',
    name: 'AnswerOverview',
    component: AnswerOverview,
  }, {
    path: '/account',
    name: 'Account',
    component: Account,
  }, {
    path: '/results/:testIdentifier',
    name: 'Results',
    component: Results,
  }, {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  }, {
    path: '*',
    name: 'ErrorPage',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
