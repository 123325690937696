<template>
  <div class="chart-row">
    <div class="chart-content">
      <h1 class="titleLabel">{{ title }}</h1>
      <div class="chart-display" @mouseover="$emit('hoverOverBar')" ref="chartDisplay">
        <div class="title-circle">
          <h1>{{ title[0] }}</h1>
        </div>
        <div class="chart-bar" ref="bar"></div>
      </div>
      <button class="dropdown-button" @click="expandTextExplanation">
        <font-awesome-icon :icon="iconName" class="icon"/>
      </button>
    </div>

    <transition name="fade">
      <div class="text-explanation" v-if="textExplanationVisibe">
        <p>{{ description }}</p>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'ChartRow',
  data() {
    return {
      textExplanationVisibe: false,
      iconName: 'chevron-down',
    };
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    highScore: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  methods: {
    calculateRowWidth() {
      // eslint-disable-next-line prefer-destructuring
      const bar = this.$refs.bar;
      const value = this.value / this.highScore;

      // +50 for bubble width
      // - 10 for -10 margin left
      const chartDisplayWidth = (this.$refs.chartDisplay.offsetWidth - 50) + 10;

      const barWidth = chartDisplayWidth * value;
      bar.style.width = `${barWidth}px`;
    },
    setColor() {
      if (this.index > 1) {
        this.$refs.bar.style.backgroundColor = '#99A09F';
      }
    },
    expandTextExplanation() {
      if (this.textExplanationVisibe) {
        this.textExplanationVisibe = false;
        this.iconName = 'chevron-down';
      } else {
        this.textExplanationVisibe = true;
        this.iconName = 'chevron-up';
      }
    },
    handleResize() {
      this.calculateRowWidth();
    },
  },
  mounted() {
    this.calculateRowWidth();
    this.setColor();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="sass">
.chart-row
  @include flex-column(center)
  @include md-max
    width: 90vw

.chart-content
  height: $chart-row-height
  @include sm-max
    @include flex-row(center)
  @include md
    @include flex-row(flex-start)

.chart-display
  @include flex-row(flex-start)
  align-items: center
  @include sm-max
    width: 40%
  @include md
    width: 50%
  @include lg
    width: 400px

.titleLabel
  text-align: left
  @include sm-max
    @include flex-column(center)
    font-size: 17px
    width: 50%
  @include md
    font-size: 17px
    width: 40%
  @include lg
    font-size: 26px
    width: 280px

.title-circle
  background-color: white
  border-radius: 50%
  border: 2px solid $orange
  z-index: 2

  @include sm-max
    width: $title-button-wh * 0.75
    height: $title-button-wh * 0.75
  @include md
    width: $title-button-wh
    height: $title-button-wh

  & h1
    color: $orange
    margin: 0

    @include sm-max
      font-size: 30px
    @include md
      font-size: 40px

.chart-bar
  background-color: $orange
  border-radius: 0 5px 5px 0
  margin-left: -10px
  -webkit-transition: width 1.2s ease-out
  transition: width 1.2s ease-out
  width: 0px

  @include sm-max
    height: $chart-bar-height * 0.75
  @include md
    height: $chart-bar-height

.dropdown-button
  background-color: transparent
  border: none
  cursor: pointer
  outline: none
  @include md-max
    align-self: flex-end
    height: 100%
    width: 10%
  @include lg
    display: none
  & .icon
    color: $orange

.fade-enter-active, .fade-leave-active
  transition: opacity .2s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
