<template>
  <div
    v-if="isShowing"
    ref="interactElement"
    class="two-sided-card"
    :class="{
      isAnimating: isInteractAnimating,
      isCurrent: isCurrent
    }"
    :style="{ transform: transformString }"
  >
    <div class="scene scene--card">
      <div class="card" ref="card">
        <rotatable-card-face
          class="card__face"
          :sideImageUrl="frontImageSrc"
          :sideImageAlt="card.frontImageAlt"
        />
        <rotatable-card-face
          class="card__face"
          :isBack="true"
          :sideImageUrl="backImageSrc"
          :sideImageAlt="card.backImageAlt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RotatableCardFace from '@/components/tests/swipeableCards/rotatableCardComponents/RotatableCardFace.vue';
import interact from 'interactjs';

const ACCEPT_CARD = 'cardAccepted';
const REJECT_CARD = 'cardRejected';

export default {
  name: 'RotatingGameCard',
  props: {
    card: {
      type: Object,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      interactPosition: {
        x: 0,
        y: 0,
        rotation: 0,
      },
      isInteractAnimating: true,
      isInteractDragged: null,
      isShowing: true,
      frontImageSrc: '',
      backImageSrc: '',
    };
  },
  components: {
    RotatableCardFace,
  },
  computed: {
    transformString() {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        const { x, y, rotation } = this.interactPosition;
        return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
      }
      return null;
    },
  },
  methods: {
    rotateCard() {
      this.$refs.card.classList.toggle('is-flipped');
      this.addCardInteraction();
      this.$emit('showingBack');
    },
    interactSetPosition(coordinates) {
      const { x = 0, y = 0, rotation = 0 } = coordinates;
      this.interactPosition = { x, y, rotation };
    },
    interactUnsetElement() {
      interact(this.$refs.interactElement).unset();
      this.isInteractDragged = true;
    },
    resetCardPosition() {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
    },
    hideCard() {
      setTimeout(() => {
        this.isShowing = false;
        this.$emit('hideCard', this.card);
      }, 300);
    },
    playCard(interaction) {
      const { interactOutOfSightXCoordinate, interactMaxRotation } = this.$options.static;

      this.interactUnsetElement();

      switch (interaction) {
        case ACCEPT_CARD:
          this.interactSetPosition({
            x: interactOutOfSightXCoordinate,
            rotation: interactMaxRotation,
          });
          this.$emit(ACCEPT_CARD);
          break;
        case REJECT_CARD:
          this.interactSetPosition({
            x: -interactOutOfSightXCoordinate,
            rotation: -interactMaxRotation,
          });
          this.$emit(REJECT_CARD);
          break;
        default:
          break;
      }
      this.hideCard();
    },
    addCardInteraction() {
      const element = this.$refs.interactElement;

      interact(element).draggable({
        onstart: () => {
          this.isInteractAnimating = false;
        },
        onmove: (event) => {
          const x = this.interactPosition.x + event.dx;
          const y = this.interactPosition.y + event.dy;

          const { interactMaxRotation, interactXThreshold } = this.$options.static;
          let rotation = interactMaxRotation * (x / interactXThreshold);

          if (rotation > interactMaxRotation) {
            rotation = interactMaxRotation;
          } else if (rotation < -interactMaxRotation) {
            rotation = -interactMaxRotation;
          }

          this.interactSetPosition({ x, y, rotation });
        },
        onend: () => {
          const { x } = this.interactPosition;
          const { interactXThreshold } = this.$options.static;
          this.isInteractAnimating = true;

          if (x > interactXThreshold) {
            this.playCard(ACCEPT_CARD);
          } else if (x < -interactXThreshold) {
            this.playCard(REJECT_CARD);
          } else {
            this.resetCardPosition();
          }
        },
      });
    },
  },
  watch: {
    isVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.frontImageSrc = this.card.frontImage;
          this.backImageSrc = this.card.backImage;
        }
      },
    },
    isCurrent: {
      immediate: true,
      handler(val) {
        if (val) {
          const vue = this;
          setTimeout(() => {
            vue.rotateCard();
          }, 2000);
        }
      },
    },
  },
  beforeDestroy() {
    interact(this.$refs.interactElement).unset();
  },
  static: {
    interactMaxRotation: 15,

    // The X position of where the card has to be moved to after action
    interactOutOfSightXCoordinate: 500,

    // The distance a card should be moved to be accepted, rejected or skipped
    interactXThreshold: 100,

    maxFontSize: 32,
    minFontSize: 15,
  },
};
</script>

<style lang="sass" scoped>
$cardsTotal: 3
$cardsPositionOffset: 1.5em
$cardsScaleOffset: 0.08
$defaultTranslation: $cardsPositionOffset * $cardsTotal
$defaultScale: 1 - ($cardsScaleOffset * $cardsTotal)

.two-sided-card
  position: absolute
  @include sm-max
    margin-top: 20px
    width: 275px
    height: 275px
  @include sm
    margin-top: 50px
    width: 350px
    height: 350px
  margin: auto
  border-radius: 50% !important

  opacity: 0
  transform: translateY($defaultTranslation) scale($defaultScale)
  transform-origin: 50%, 100%
  will-change: transform, opacity

  &.isCurrent
    pointer-events: auto

  &.isAnimating
    transition: transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275)

@for $i from 1 through $cardsTotal
  $index: $i - 1
  $translation: $cardsPositionOffset * $index
  $scale: 1 - ($cardsScaleOffset * $index)

  .two-sided-card:nth-child(#{$i})
    z-index: $cardsTotal - $index
    opacity: 1
    transform: translateY($translation) scale($scale)

    @if $i == 3
      color: white
      background-color: white
    @else if $i == 2
      color: white
      background-color: white

    @if $i != 1
      & .card-image
        opacity: 0.5

      & .card-title
        opacity: 0.5

.scene
  width: 100%
  height: 100%
  perspective: 600px

.card
  width: 100%
  height: 100%
  transition: transform 1s
  transform-style: preserve-3d
  cursor: pointer
  position: relative

.card.is-flipped
  transform: rotateY(180deg)

.card__face
  position: absolute
  width: 100%
  height: 100%
  -webkit-backface-visibility: hidden
  backface-visibility: hidden
</style>
