import RiasocQuestion from '../RiasocQuestion';

const toFirestore = (riasocQuestion) => {
  return {
    id: riasocQuestion.id,
    question: riasocQuestion.question,
    riasoc_type: riasocQuestion.riasocType,
    question_active: riasocQuestion.questionActive,
    image_url: riasocQuestion.imageUrl,
    image_alt: riasocQuestion.imageAlt,
  };
};

const fromFirestore = (snapshot, options) => {
  const data = snapshot.data(options);
  return new RiasocQuestion(
    data.id,
    data.question,
    data.riasoc_type,
    data.question_active,
    data.image_url,
    data.image_alt,
  );
};

export {
  toFirestore,
  fromFirestore,
};
