<template>
  <div class="hero-parent-container">
    <div class="home-hero-container">
      <svg
        class="background-triangles-svg-container page-loading"
        viewBox="0 0 100 100"
        ref="backgroundTriangles"
      >
        <polygon class="flag-polygon" points="0 45, 0 55, 10 50"/>
        <polygon class="flag-polygon" points="40 45, 40 55, 30 50" />
        <polygon class="flag-polygon" points="25 40, 25 50, 15 45" />
        <polygon class="flag-polygon" points="20 60, 20 70, 30 65" />
        <polygon class="flag-polygon" points="15 65, 15 75, 5 70" />
        <polygon class="flag-polygon" points="15 25, 15 35, 5 30" />
        <polygon class="flag-polygon" points="40 60, 40 70, 50 65" />
        <polygon class="flag-polygon" points="35 30, 35 40, 45 35" />
        <polygon class="flag-polygon" points="55 20, 55 30, 45 25" />
        <polygon class="flag-polygon" points="25 15, 25 25, 35 20" />
        <polygon class="flag-polygon" points="60 65, 60 75, 50 70" />
        <polygon class="flag-polygon" points="30 70, 30 80, 40 75" />
      </svg>

      <div class="columns-container">
        <div class="column left-column">
          <h1 style="text-shadow: 0px 0px 20px white;">{{$styleConfiguration.home_hero_text}}</h1>
          <button
            class="square-orange-button-icon"
            v-scroll-to="'#test-body'"
          >
            <p>{{$styleConfiguration.home_hero_button}}</p>
            <font-awesome-icon icon="long-arrow-alt-right" class="icon"/>
          </button>
        </div>
        <dir class="column right-column">
          <svg class="triangle-svg-container" viewBox="0 0 100 100">
            <polygon
              class="background-triangle"
              ref="bigBackgroundTriangle"
              points="95 0, 95 100, 5 50"
            />
            <defs>
              <clipPath id="triangle">
                <polygon points="95 0, 95 100, 5 50" />
              </clipPath>
            </defs>
            <image
              width="100"
              height="100"
              :href="require(`../../${$styleConfiguration.hero_triangle_background}`)"
              clip-path="url(#triangle)"
            />
          </svg>
        </dir>
      </div>
    </div>

    <font-awesome-icon
      class="scroll-hint-container"
      icon="chevron-down"
      ref="scrollHintContainer"
      v-scroll-to="'#test-body'"
    />
  </div>
</template>

<script>
export default {
  name: 'HomeHero',
  mounted() {
    this.configureStyles();
    const vue = this;
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY;
      const winHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;
      const perc = 100 * (scrollPos / (docHeight - winHeight));

      const LOWER_BOUND = 5;
      const UPPER_BOUND = 15;
      const hintContainer = vue.$refs.scrollHintContainer;
      if (hintContainer) {
        if (perc >= LOWER_BOUND && perc < UPPER_BOUND) {
          const newOpacity = 1 - (
            (perc - LOWER_BOUND) * (1 / (UPPER_BOUND - LOWER_BOUND))
          );
          hintContainer.style.opacity = newOpacity;
        } else if (perc < LOWER_BOUND) {
          hintContainer.style.opacity = 1.0;
        } else if (perc > UPPER_BOUND) {
          hintContainer.style.opacity = 0.0;
        }
      }
    });

    this.$refs.backgroundTriangles.classList.remove('page-loading');
  },
  methods: {
    configureStyles() {
      const config = this.$styleConfiguration;
      this.$refs.backgroundTriangles.style.stroke = config.blue_hex;
      this.$refs.bigBackgroundTriangle.style.fill = config.blue_hex;
      this.$refs.scrollHintContainer.style.backgroundColor = config.light_gray_hex;
    },
  },
};
</script>

<style scoped lang="sass">
.hero-parent-container
  @include flex-column(center)
  align-items: center

  & .scroll-hint-container
    animation: bounce 2s cubic-bezier(0.280, 0.840, 0.420, 1) infinite
    cursor: pointer
    position: fixed
    bottom: 100px
    border-radius: 50%
    padding: 10px
    width: 20px
    height: 20px

    transition: 0.3s
    &:hover
      background-color: $blue
      color: white

.home-hero-container
  height: 100vh

  width: 100vw
  max-width: 1200px

  & .background-triangles-svg-container
    position: absolute
    top: 0
    left: 0
    opacity: 0.2
    width: 100%
    height: 100%
    stroke: blue
    & .flag-polygon
      fill: #00000000
      stroke-width: 0.5

  & .columns-container
    @include flex-row(center)
    height: 100%
    & .left-column
      @include flex-column(center)
      justify-content: center
      align-items: flex-start
      z-index: 10
      padding: $standard-margin-size
      @include sm-max
        width: 100%
      @include md
        width: 30%

    & .right-column
      padding: 0
      margin: 0
      @include sm-max
        position: absolute
        right: 0
        height: 100vh
        width: 100%
      @include md
        @include flex-column(center)
        width: 50%

      & .triangle-svg-container
        width: 100%
        height: 100%
        & .background-triangle
          fill: green

.flag-polygon
  animation: pop-up 1s cubic-bezier(0,.84,.72,.73) forwards
  transform: scale(0)
.flag-polygon:nth-child(1)
  animation-delay: 0s
.flag-polygon:nth-child(2)
  animation-delay: 0.2s
.flag-polygon:nth-child(3)
  animation-delay: 0.4s
.flag-polygon:nth-child(4)
  animation-delay: 0.6s
.flag-polygon:nth-child(5)
  animation-delay: 0.8s
.flag-polygon:nth-child(6)
  animation-delay: 1s
.flag-polygon:nth-child(7)
  animation-delay: 1.2s
.flag-polygon:nth-child(8)
  animation-delay: 1.4s
.flag-polygon:nth-child(9)
  animation-delay: 1.6s
.flag-polygon:nth-child(10)
  animation-delay: 1.8s
.flag-polygon:nth-child(11)
  animation-delay: 2.0s
.flag-polygon:nth-child(12)
  animation-delay: 2.2s

@keyframes pop-up
  0%
    transform: scale(0.0)
    opacity: 0.0
  60%
    opacity: 1.0
  80%
    transform: scale(1.05)
  100%
    transform: scale(1.0)

@keyframes bounce
  0%
    transform: scale(1,1)      translateY(0)
  5%
    transform: scale(1.1,.9)   translateY(0)
  15%
    transform: scale(.9,1.1)   translateY(-10px)
  25%
    transform: scale(1.05,.95) translateY(5px)
  58%
    transform: scale(1,1)      translateY(0)
  37%
    transform: scale(1,1)      translateY(0)
  60%
    transform: scale(1,1)      translateY(0)
  100%
    transform: scale(1,1)      translateY(0)


</style>
