/* eslint-disable object-shorthand */
import { functions } from '../../firebase';

const alterUserPermissions = (uid, clear, staff, editor, superuser) => {
  const alterPermissions = functions.httpsCallable('alterPermissions');
  return alterPermissions({
    uid: uid,
    clear: clear,
    staff: staff,
    editor: editor,
    superuser: superuser,
  });
};

export {
  alterUserPermissions,
};
