<template>
  <div
    class="waarde-card"
    :class="{ 'selected-waarde': waarde.answer, 'deselected-waarde': !waarde.answer }"
  >
    <img
      class="main-image"
      :src="waarde.card.frontImage"
      :alt="waarde.card.frontImageAlt"
      @click="$emit('cardClicked', waarde)"
      :ref="'bubble-image-' + waarde.card.id"
    >

    <button
      id="inspect-card-button"
      @click="$emit('cardInspectClicked', waarde)"
    >
      <img src="@/assets/images/waarden/inspect_card_icon.png" alt="Bekijk deze kaart"/>
    </button>
  </div>
</template>

<script>

export default {
  name: 'WaardenCardsView',
  props: {
    waarde: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>

$card-width-height: 12em
$card-margin: 1.5em
$border-width: 0.3em
$inspection-button-inset: 20px
$inspection-button-width-height: 6em

.waarde-card
  width: calc(#{$card-width-height + 2*$card-margin}) !important
  height: calc(#{$card-width-height + 2*$card-margin}) !important
  margin: $card-margin
  cursor: pointer
  & img
    border-radius: 50%
    width: $card-width-height
    height: $card-width-height
    -webkit-user-drag: none
    -khtml-user-drag: none
    -moz-user-drag: none
    -o-user-drag: none
    user-drag: none

  & #inspect-card-button
    background-color: transparent
    border: none
    cursor: pointer
    position: absolute
    bottom: $inspection-button-inset
    right: $inspection-button-inset
    & img
      width: $inspection-button-width-height
      height: $inspection-button-width-height

.selected-waarde
  & .main-image
    border: 0.3em solid $orange
    -webkit-box-shadow: 0px 0px 10px 0px rgba($orange ,1)
    box-shadow: 0px 0px 10px 0px rgba($orange ,1)

.deselected-waarde
  & .main-image
    border: 0.3em solid white
    -webkit-box-shadow: 0px 0px 10px 0px rgba(gray ,1)
    box-shadow: 0px 0px 10px 0px rgba(gray ,1)

</style>
