import UserAnswers from '../UserAnswers';

const toFirestore = (userAnswers) => {
  return {
    all_answers: userAnswers.allAnswers,
    is_visible_for_user: userAnswers.isVisibleForUser,
    question_type: userAnswers.questionType,
    user: userAnswers.user,
  };
};

const fromFirestore = (snapshot, options) => {
  const data = snapshot.data(options);
  return new UserAnswers(
    data.all_answers,
    data.is_visible_for_user,
    data.question_type,
    data.user,
  );
};

export {
  toFirestore,
  fromFirestore,
};
