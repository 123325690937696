// answerSubmissionLog.js
import { answerSubmissionLogCollection } from '../firebase';
import { AnswerSubmissionLogConverter } from '../../index';

const getAll = (startDate) => {
  return answerSubmissionLogCollection
    .where('upload_date', '>=', startDate)
    .withConverter(AnswerSubmissionLogConverter)
    .get();
};

const getAllFilteredOnSchool = (startDate, schoolName) => {
  return answerSubmissionLogCollection
    .where('upload_date', '>=', startDate)
    .where('school_name', '==', schoolName)
    .withConverter(AnswerSubmissionLogConverter)
    .get();
};

const create = (data) => {
  return answerSubmissionLogCollection
    .add(data);
};


export {
  getAll,
  getAllFilteredOnSchool,
  create,
};
