export default class TwoSidedImageCardQuestion {
  constructor(id, question, questionActive, frontImage, frontImageAlt, backImage, backImageAlt) {
    this.id = id;
    this.question = question;
    this.questionActive = questionActive;
    this.frontImage = frontImage;
    this.frontImageAlt = frontImageAlt;
    this.backImage = backImage;
    this.backImageAlt = backImageAlt;
  }
}
