<template>
  <div class=error-parent-container>
    <background-triangle/>
    <div class="error-container">
      <div class="header-container">
        <h1 class="big-title">Sorry, er is iets fout gegaan</h1>

      </div>

      <div class="body-container text-center">
        <p>
          Ga terug naar de vorige pagina. Weet je niet meer waar je bent? Ga dan terug naar de
          home pagina.
        </p>

        <div class="button-container">
          <button class="primary-button-orange" @click="$router.go(-1)">Terug</button>
          <button class="primary-button-orange" @click="$router.push({ name: 'Home' })">
            Home
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundTriangle from '@/components/BackgroundTriangle.vue';

export default {
  name: 'ErrorPage',
  components: {
    BackgroundTriangle,
  },
};
</script>

<style lang="sass" scoped>
.error-parent-container
  @include flex-column(center)
  align-items: center
  min-height: calc(100vh - #{$footer-height})

.error-container
  padding: $standard-margin-size
  max-width: 400px

.button-container
  & button
    margin: 10px
</style>
