<template>
  <div>
    <div class="header-container">
      <img
        src="@/assets/images/backgrounds/triangle_background.png"
        class="triangle-background-image"
        alt="Achtergrond"
      />
      <h1>
        {{ userInformation.firstName }},
        hier jouw karaktereigenschappen op een rij!
      </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IdentiteitResultsHeader',
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
    }),
  },
};
</script>

<style lang="sass" scoped>
.header-container
  @include flex-column(center)
  align-items: center
  padding: 0 $standard-margin-size
  height: 300px

  & h1
    @include sm-max
      text-align: center
      margin: 0 20px

  & .triangle-background-image
    position: absolute
    height: 300px
    width: calc(90vw - 2 * #{$standard-margin-size})
    z-index: -10
</style>
