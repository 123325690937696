<template>
  <div class="content-container">
    <scale-loader v-if="chartLoading" color="#F26000"></scale-loader>

    <IdentiteitCardsResults
      :results="identiteitResults"
      :answersEditable="false"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import IdentiteitCardsResults from '@/components/results/identiteit/IdentiteitCardsResults.vue';

export default {
  name: 'IdentiteitResultsBody',
  data() {
    return {
      chartLoading: false,
    };
  },
  components: {
    ScaleLoader,
    IdentiteitCardsResults,
  },
  computed: {
    ...mapGetters({
      identiteitResults: 'identiteitResults/identiteitResults',
    }),
  },
  mounted() {
    this.chartLoading = true;

    if (this.identiteitResults) {
      this.chartLoading = false;
    }
  },
  watch: {
    identiteitResults() {
      this.chartLoading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.content-container
  @include flex-column(center)
  padding: 0 $standard-margin-size
  @include sm-max
    width: calc(100vw - 2 * #{$standard-margin-size})
  @include md
    width: calc(100% - 2 * #{$standard-margin-size})
  & button
    margin: 20px
  & p
    margin: 20px
</style>
