import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

// firebase init - add your own config here
const configOptions = {
  apiKey: 'AIzaSyAWsAmWeA8Z-xsTwLWlIi283SzEiqac8gc',
  authDomain: 'kieswijs-5e1a6.firebaseapp.com',
  projectId: 'kieswijs-5e1a6',
  storageBucket: 'kieswijs-5e1a6.appspot.com',
  messagingSenderId: '747789705915',
  appId: '1:747789705915:web:e0cf4da72f4c173cb8bf9e',
};

firebase.initializeApp(configOptions);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();
const { firestore } = firebase;
const firebaseStorage = firebase.storage;
const firebaseFirestore = firebase.firestore;

if (process.env.JEST_WORKER_ID !== undefined) {
  console.log('Running a Jest Test mode');
  db.useEmulator('localhost', 9997);  
}

// // collection references
const usersAnswersCollection = db.collection('user_answers');
const questionsCollection = db.collection('questions');
const userInformationCollection = db.collection('user_information');
const contactFormSubmissionsCollection = db.collection('contact_form_submissions');
const answerSubmissionLogCollection = db.collection('answer_submission_log');

export {
  db,
  auth,
  storage,
  firebaseStorage,
  firebaseFirestore,
  firestore,
  functions,

  usersAnswersCollection,
  questionsCollection,
  userInformationCollection,
  answerSubmissionLogCollection,
  contactFormSubmissionsCollection,
};
