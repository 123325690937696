import { questionsCollection, firestore } from '../firebase';
import { QuestionSetConverter, RiasocQuestionConverter, TwoSidedImageCardQuestionConverter } from '../../index';

const getAll = (setName) => {
  return questionsCollection
    .where('set_name', '==', setName)
    .withConverter(QuestionSetConverter)
    .get();
};

const addQuestion = (setId, question, questionType) => {
  const questionForFirebase = getConvertedJsonQuestion(question, questionType);

  return questionsCollection
    .doc(setId)
    .update({
      questions: firestore.FieldValue.arrayUnion(questionForFirebase),
    });
};

const removeQuestion = (setId, question, questionType) => {
  const questionForFirebase = getConvertedJsonQuestion(question, questionType);

  return questionsCollection
    .doc(setId)
    .update({
      questions: firestore.FieldValue.arrayRemove(questionForFirebase),
    });
};

const getConvertedJsonQuestion = (question, questionType) => {
  let questionForFirebase;
  switch (questionType) {
    case 'riasoc':
      questionForFirebase = RiasocQuestionConverter.toFirestore(question);
      break;
    case 'identiteit':
    case 'waardenspel':
      questionForFirebase = TwoSidedImageCardQuestionConverter.toFirestore(question);
      break;
    default:
      break;
  }
  return questionForFirebase;
};

export {
  getAll,
  addQuestion,
  removeQuestion,
  getConvertedJsonQuestion,
};
